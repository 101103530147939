import React from "react";
import { Form, Modal } from "react-bootstrap";
import AddedRecord from "./animation/AddedRecord.js";
import DeleteRecord from "./animation/DeleteRecord.js";

const AddedLottieModal = (props) => {
  console.log("props", props);

  return (
    <section className="modal-common-class-hrms">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-modal"
      >
        <Modal.Body>
          <div className="below-bodyy">
            <div className="donwload-div d-flex justify-content-center">
              {props.success ? <AddedRecord /> : <DeleteRecord />}

              <h6 className="mt-2">
                {props?.message || "Added Successfully!!"}
              </h6>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default AddedLottieModal;
