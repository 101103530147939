import React from "react";
import { Route, Routes } from "react-router-dom";
import HrmsDashboard from "../Hrms-Setup-Content/Hrms-Dashboard/HrmsDashboard";
import Department from "../Hrms-Setup-Content/Department/Department";
import Designation from "../Hrms-Setup-Content/Designation/Designation";
import Skills from "../Hrms-Setup-Content/Skills/Skills";
import LeaveType from "../Hrms-Setup-Content/LeaveType/LeaveType";
import LeaveMaster from "../Hrms-Setup-Content/Leave/Leave";
import EmployeeType from "../Hrms-Setup-Content/EmployeeType/EmployeeType";
import Assets from "../Hrms-Setup-Content/Assets/Assets";
import Shifts from "../Hrms-Setup-Content/Shifts/Shifts";
import Qualification from "../Hrms-Setup-Content/Qualification/Qualification";
import University from "../Hrms-Setup-Content/University/University";
import Course from "../Hrms-Setup-Content/Course/Course";
import Employee from "../Hrms-Dashboard-Content/Employee/Employee";
import Leave from "../Hrms-Dashboard-Content/Leave/Leave";
import Attendance from "../Hrms-Dashboard-Content/Attendance/Attendance";
import PaymentSlip from "../Hrms-Dashboard-Content/Payment-Slip/PaymentSlip";
import AddEmployee from "../Hrms-Dashboard-Content/Employee/EmployeeModal/AddEmployee";
import DashboardAssets from "../Hrms-Dashboard-Content/Assets/DashboardAssets";
import DashBoardShifts from "../Hrms-Dashboard-Content/Shifts/DashBoardShifts";
import EditEmployee from "../Hrms-Dashboard-Content/Employee/EmployeeModal/EditEmployee";
import ViewEmployee from "../Hrms-Dashboard-Content/Employee/EmployeeModal/ViewEmployee";
import HrmsProfile from "../Hrms-Profile/HrmsProfile";
import CalendarHrms from "../Hrms-Setup-Content/Calendar-Hrms/CalendarHrms";

const Hroutes = () => {
  return (
    <Routes>
      {/* HRMS Setup Content */}
      <Route path="/hrms-dashboard" element={<HrmsDashboard />} />
      <Route path="/hrms-department" element={<Department />} />
      <Route path="/hrms-designation" element={<Designation />} />
      <Route path="/hrms-skills" element={<Skills />} />
      <Route path="/hrms-leave-type" element={<LeaveType />} />
      <Route path="/hrms-leave" element={<LeaveMaster />} />
      <Route path="/hrms-employee-type" element={<EmployeeType />} />
      <Route path="/hrms-setup-assets" element={<Assets />} />
      <Route path="/hrms-setup-shifts" element={<Shifts />} />
      <Route path="/hrms-setup-qualification" element={<Qualification />} />
      <Route path="/hrms-setup-university" element={<University />} />
      <Route path="/hrms-setup-course" element={<Course />} />

      {/* HRMS dashboard Content */}
      <Route path="/hrms-employee" element={<Employee />} />
      <Route path="/hrms-leave" element={<Leave />} />
      <Route path="/hrms-calendar" element={<CalendarHrms />} />
      <Route path="/hrms-attendance" element={<Attendance />} />
      <Route path="/hrms-payment-slip" element={<PaymentSlip />} />
      <Route path="/hrms-assets" element={<DashboardAssets />} />
      <Route path="/hrms-shift" element={<DashBoardShifts />} />

      {/* Employee */}
      <Route path="/add-employee" element={<AddEmployee />} />
      <Route path="/edit-employee/:id" element={<EditEmployee />} />
      <Route path="/view-employee/:id" element={<ViewEmployee />} />

      {/* Profile */}
      <Route path="/hrms-profile" element={<HrmsProfile />} />
    </Routes>
  );
};

export default Hroutes;
