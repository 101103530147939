import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";

import search1 from "../../../../Components/Admin/assets/icons/search.png";
import Table from "react-bootstrap/Table";

import Header from "../../Header/Header";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import toast, { Toaster } from "react-hot-toast";

import "./AttendanceTable.css";
import { Link } from "react-router-dom";
import plus from "../../../../Components/Admin/assets/icons/a1.png";
import Card from "react-bootstrap/Card";
import Offcanvas from "react-bootstrap/Offcanvas";

import Select from "react-select";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";

library.add(fas);

const TableOffCanvance = (props) => {
  const { postData, getData } = useContext(Context);

  const id = props.show;

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(`/hrm/attendance/present/${id}`);
    await setData(response);
  };
  useEffect(() => {
    getDataAll();
  }, []);

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%", height: "80%", margin: "auto" }}
        placement={"top"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Attendance Mark</Card.Title>
              <hr />
              <Container>
                <Table striped bordered hover responsive center>
                  <thead>
                    <tr className="">
                      <th className="sr">Sr. No.</th>
                      <th className="tax-name">Clock In</th>
                      <th className="tax-name">Clock In Input</th>
                      <th className="tax-name">Clock Out</th>
                      <th className="tax-name">Clock Out Input</th>
                      <th className="tax-name">Late</th>
                      <th className="tax-name">Half Day</th>
                      <th className="tax-name">Remark </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.map((d, index) => {
                      return (
                        <tr className="" key={index}>
                          <td>{++index}.</td>
                          <td>{d.clock_in}</td>
                          <td>{d.clock_in_ip}</td>
                          <td>{d.clock_out}</td>
                          <td>{d.clock_out_ip}</td>
                          <td>
                            {" "}
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                // role="switch"
                                checked={d.late}
                                id={`flexSwitchCheckDefault${d?.id}`}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`flexSwitchCheckDefault${d?.id}`}
                              ></label>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                // role="switch"
                                checked={d.half_day}
                                id={`flexSwitchCheckDefault${d?.id}`}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`flexSwitchCheckDefault${d?.id}`}
                              ></label>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Add remark"
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const Tables = () => {
  const { getData, editStatusData, deleteData, ErrorNotify } =
    useContext(Context);

  const [days, setDays] = useState([]);
  const [show3, setShow3] = useState(0);
  const [users, setUsers] = useState([]);
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([
    {
      value: 1,
      name: "month",
      label: "January",
    },

    {
      value: 2,
      name: "month",
      label: "February",
    },

    {
      value: 3,
      name: "month",
      label: "March",
    },

    {
      value: 4,
      name: "month",
      label: "April",
    },

    {
      value: 5,
      name: "month",
      label: "May",
    },

    {
      value: 6,
      name: "month",
      label: "June",
    },

    {
      value: 7,
      name: "month",
      label: "July",
    },

    {
      value: 8,
      name: "month",
      label: "August",
    },

    {
      value: 9,
      name: "month",
      label: "September",
    },

    {
      value: 10,
      name: "month",
      label: "October",
    },

    {
      value: 11,
      name: "month",
      label: "November",
    },

    {
      value: 12,
      name: "month",
      label: "December",
    },
  ]);

  const [user, setUser] = useState([]);

  const [currentdate, setCurrentDate] = useState(0);

  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);

  const [searchuser, setSearchuser] = useState([]);
  const [searchmonth, setSearchmonth] = useState([]);
  const [searchyears, setSearchyears] = useState([]);

  const getDataAll = async () => {
    const responseUsers = await getData("/hrm/attendance");
    const response = await getData(
      `/hrm/attendance?user=${searchuser.value ? searchuser.value : ""}&month=${
        searchmonth.value ? searchmonth.value : ""
      }&year=${searchyears.value ? searchyears.value : ""}`
    );

    await setDays(response?.data?.monthDetailsArray);
    await setUsers(response?.data?.structuredData);

    response?.data?.monthDetailsArray?.map((day, index) =>
      day.isCurrentDate ? setCurrentDate(day.date) : null
    );

    const years = [];

    let no = 2020;
    while (no <= new Date().getFullYear()) {
      years.push({
        value: no,
        name: "year",
        label: no,
      });

      ++no;
    }

    setYears(years);

    const allusers = await getData("/masters/allusers");

    const option = [];
    if (allusers.success) {
      allusers?.data?.map((data) => {
        option.push({
          value: data.userid,
          name: "user_id",
          label: data.user?.name,
        });
      });
    }
    setUser(option);
  };

  useEffect(() => {
    getDataAll();
  }, [reset, show, show1]);

  const handleClose3 = () => setShow3(0);
  const handleShow3 = (id) => {
    setShow3(id);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Attendance"} link={"/hrm/attendance"} />
        <section className="AdvanceDashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <section className="Tabels tab-radio tab-radio mt-3">
              <div className="">
                <h1
                  style={{ textAlign: "center", margin: "0%" }}
                  className="mt-1"
                >
                  Attendance
                </h1>
                <div className="border-line mt-3 mb-3"></div>
                <div className="row">
                  <div className="col-lg-12  col-md-12 col-12 justify-content-end">
                    <Row>
                      <Col md={3}>
                        <Select
                          placeholder="Employee"
                          className="custom-select"
                          onChange={(e) => {
                            setSearchuser(e);
                          }}
                          value={searchuser}
                          options={user}
                        />
                      </Col>

                      <Col md={3}>
                        <Select
                          placeholder="Month"
                          className="custom-select"
                          onChange={(e) => {
                            setSearchmonth(e);
                          }}
                          value={searchmonth}
                          options={months}
                        />
                      </Col>
                      <Col md={3}>
                        <Select
                          placeholder="Year"
                          className="custom-select"
                          onChange={(e) => {
                            setSearchyears(e);
                          }}
                          value={searchyears}
                          options={years}
                        />
                      </Col>

                      <Col md={3}>
                        <button
                          type="button"
                          onClick={getDataAll}
                          className="btn btn-search me-3"
                        >
                          <img src={search1} className="search" alt="" />
                        </button>

                        <button
                          type="button"
                          onClick={() => {
                            setSearchuser("");
                            setSearchmonth("");
                            setSearchyears("");
                            setReset(!reset);
                          }}
                          className="btn btn-reset"
                        >
                          Reset
                        </button>
                      </Col>
                    </Row>

                    <div className="num me-2"></div>
                  </div>

                  <div className="border-line mt-3"></div>
                </div>
                <div className="row mt-3">
                  {/* {searchmonth && (
                    <h4>
                      {" "}
                      <span className="currentmon">
                        {
                          months.find(
                            (month) => month.value === searchmonth.value
                          )?.label
                        }
                        ,
                        {searchyears && (
                          <span>
                            {
                              years.find(
                                (years) => years.value === searchyears.value
                              )?.label
                            }
                          </span>
                        )}
                      </span>
                    </h4>
                  )} */}

                  <div className="data table-responsive attedance">
                    <Table striped bordered hover responsive center>
                      <thead>
                        <tr>
                          {<th className="tax-name">Employee</th>}
                          {days?.map((day, index) => (
                            <th className="sr" key={index}>
                              {day.date}
                              <br />
                              {day.week}
                            </th>
                          ))}
                          <th className="sr">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users?.map((student, studentIndex) => (
                          <tr key={studentIndex}>
                            <td>{student.name}</td>
                            {student?.attendance?.map((data, dayIndex) => (
                              <td key={dayIndex}>
                                {data.isPresent ? (
                                  <Link>
                                    <div
                                      onClick={() => handleShow3(student?.id)}
                                    >
                                      <FontAwesomeIcon icon="fa-solid fa-check" />
                                    </div>
                                  </Link>
                                ) : (
                                  <FontAwesomeIcon icon="fa-solid fa-xmark" />
                                )}
                              </td>
                            ))}
                            <td>{student.total}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>

      {show3 ? (
        <TableOffCanvance
          handleClose={handleClose3}
          setShow={setShow3}
          show={show3}
        />
      ) : (
        ""
      )}

      <Toaster position="top-right" />
    </>
  );
};

export default Tables;
