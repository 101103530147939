import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import DeleteLottieModal from '../../../commonLottie/DeleteLottieModal';

const DeleteModal = (props) => {

  const [showLottie, setshowLottie] = useState(false);

  const handleDownloadClick = () => {

      props.onHide()

      setshowLottie(true); // Show the modal

      // Hide modal after 2 seconds
      setTimeout(() => {
          setshowLottie(false);
      }, 3000);
  };

    return (
        <section className='modal-common-class-hrms'>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="add-modal"
            >
                <Modal.Body>
                    <div className='text-center'>
                        <h4 className='modal-title-common'>Delete Asset</h4>
                    </div>
                    <div className='below-bodyy'>
                        <div className='text-center'>
                            <p>Are you sure want to Delete?</p>
                        </div>
                        <div className='text-center mt-5 mb-5'>
                            <button className='cancel-button me-2 ' >Cancel</button>
                            <button onClick={handleDownloadClick}  className='save-button'>Delete</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <DeleteLottieModal pageTitle="Asset" show={showLottie}
                onHide={() => setshowLottie(false)} />
        </section>
    )
}

export default DeleteModal