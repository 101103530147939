import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './ApplyLeaveModal.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'; // Import the specific icon
import ApplyLeaveLottieModal from './ApplyLeaveLottieModal';


const ApplyLeaveModal = (props) => {

    
    const [showLottie, setshowLottie] = useState(false);

    const handleDownloadClick = () => {

        props.onHide()

        setshowLottie(true); // Show the modal

        // Hide modal after 2 seconds
        setTimeout(() => {
            setshowLottie(false);
        }, 3000);
    };


    return (
        <div >
            <Modal
                {...props}
                size="md"
                className='ApplyLeaveModal'
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body >
                 
                    <div className="ApplyLeaveModal-div">
                        <div className='ApplyLeaveModal-duration'>
                            <label className='duration-label'>Leave Duration Date</label>
                            <div className='outer-duration-label row'>
                                <div className='col-lg-6  inner-duration-div'>
                                    <label className='inner-duration-label'>From</label><br />
                                    <DatePicker
                                        // selected={startDate} // Uncomment and set state for the selected date
                                        // onChange={(date) => setStartDate(date)} // Uncomment and handle the date change
                                        className="inner-duration-date"
                                        placeholderText="dd/mm/yyyy" // This will act as the placeholder text
                                    />

                                </div>
                                <div className='col-lg-6   inner-duration-div'>
                                    <label className='inner-duration-label'>To</label><br />
                                    <DatePicker
                                        // selected={startDate} // Uncomment and set state for the selected date
                                        // onChange={(date) => setStartDate(date)} // Uncomment and handle the date change
                                        className="inner-duration-date"
                                        placeholderText="dd/mm/yyyy" // This will act as the placeholder text
                                    />

                                </div>
                            </div>
                        </div>
                        <div className='ApplyLeaveModal-count'>
                            <label>Leave Count (Days)</label>
                            <div className='text-div'>
                                <input className='count-input' type='text' placeholder='Enter Days Count' />
                            </div>
                        </div>
                        <div className='ApplyLeaveModal-reason'>
                            <label>Leave Reason</label>
                            <div className='reason-div'>
                                <textarea placeholder='Write From Here...' className='textarea'></textarea>
                            </div>
                        </div>
                        <div className='button-div'>
                            <button onClick={handleDownloadClick} className='btn'>Apply Leave</button>
                        </div>
                    </div>
                </Modal.Body>


                <div className='cross-div' onClick={props.onHide}>
                        <FontAwesomeIcon className='icon' icon={faCircleXmark} />
                    </div>
            </Modal>

            <ApplyLeaveLottieModal pageTitle="Designation" show={showLottie}
                onHide={() => setshowLottie(false)} />

        </div>
    );
};

export default ApplyLeaveModal;