import { Modal } from "react-bootstrap";
import React, { useContext, useState, useEffect } from "react";
import "../../../../Admin/Tabels/Tabels.css";
import { Context } from "../../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import ModalSave from "../../../../common/ModalSave";
import { CancelButton } from "../../../../common/Button";
import EditLottieModal from "../../../commonLottie/EditLottieModal";

library.add(fas);

const EditModal = (props) => {
  const [showLottie, setshowLottie] = useState(false);
  const id = props.show;
  const { postData, getData } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const GetEditData = async () => {
    const response = await getData(`/hrms-masters/university/${id}`);
    reset(response?.data);
  };

  useEffect(() => {
    if (id) {
      GetEditData();
    }
  }, [id]);

  const onSubmit = async (data) => {
    try {
      const sendData = new FormData();
      sendData.append("name", data?.name);

      if (!id) {
        throw new Error("ID is missing");
      }

      const response = await postData(
        `/hrms-masters/university/${id}`,
        sendData
      );
      console.log(response);

      if (response?.success) {
        setshowLottie({ code: 1, message: "Success!" });
      } else {
        setshowLottie({ code: -1, message: "Failed to submit." });
      }

      setTimeout(() => {
        setshowLottie({ code: 0, message: "" });
        props.handleClose();
      }, 3000);
    } catch (error) {
      console.log("Error submitting form: ", error);
      setshowLottie({ code: -1, message: "An error occurred." });
    }
  };

  return (
    <section className="modal-common-class-hrms">
      <Modal
        show={props.show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-modal"
        onHide={props.handleClose}
      >
        <Modal.Body>
          <div className="text-center">
            <h4 className="modal-title-common">Edit University</h4>
          </div>
          <Container>
            <Row>
              <Col md={12}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col lg={8} md={10} className="mx-auto Add-content">
                      <div className="main-form-section mt-5">
                        <Form.Label>University</Form.Label>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <InputGroup>
                            <Form.Control
                              type="text"
                              name="name"
                              placeholder="University"
                              className={classNames("", {
                                "is-invalid": errors?.name,
                              })}
                              {...register("name", {
                                required: "University is required",
                              })}
                            />
                          </InputGroup>
                          {errors.name && (
                            <span className="text-danger">
                              {errors.name.message}
                            </span>
                          )}
                        </Form.Group>
                      </div>
                      <div className="d-flex justify-content-center mt-5 pb-3">
                        <CancelButton
                          name="cancel"
                          handleClose={props.handleClose}
                        />
                        <button type="submit" className="save-button">
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <EditLottieModal
        pageTitle="University"
        show={showLottie}
        onHide={() => setshowLottie(false)}
      />
    </section>
  );
};

export default EditModal;
