import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../CommonCss/CommonCss.css";
// import plus from "../../assets/icons/a1.png";
import colunms from "../../../../Components/Admin/assets/icons/LINES.png";
import {
  Button,
  Col,
  Dropdown,
  Form,
  InputGroup,
  Container,
  Row,
  Table,
} from "react-bootstrap";

import Select from "react-select";
import Card from "react-bootstrap/Card";
import toast, { Toaster } from "react-hot-toast";

import { Context } from "../../../../utils/context";

import { Link, useNavigate, useParams } from "react-router-dom";
import search1 from "../../../../Components/Admin/assets/icons/search.png";
import { AddButton, EditButton, DeletButton } from "../../../common/Button";
import ModalDelete from "../../../common/ModalDelete";
import Header from "../../../Admin/Header/Header";
import Offcanvas from "react-bootstrap/Offcanvas";
import AddOffCanvance from "./LeaveModal/AddModal";

import { Calendar, momentLocalizer } from "react-big-calendar";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
const localizer = momentLocalizer(moment);
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData } = useContext(Context);

  const navigate = useNavigate();
  const id = props.show;

  const [show, setShowAdd] = useState(false);
  const [events, setEvents] = useState([]);

  console.log(events, "events");

  const [changeStatus, setChangeStatus] = useState();

  const getDataAll = async () => {
    const response = await getData(`/hrms-masters/leave`);
    let MyArray = [];
    response?.data?.data?.map((d, index) => {
      const startDate = new Date(d.start);
      let endDate = new Date(d.end);

      // If it's a single-day event, set the end date to the same as the start date
      if (d.duration === "Single") {
        endDate = startDate;
      }

      const temp = {
        id: d.id,
        title: d.title,
        duration: d.duration,
        start: startDate,
        end: endDate,
        color: d?.leave_type?.color,
      };
      MyArray.push(temp);
    });
    setEvents(MyArray);
  };

  useEffect(() => {
    getDataAll();
  }, []);

  const eventStyleGetter = (event, start, end, isSelected) => {
    return {
      style: {
        backgroundColor: event?.color,
      },
    };
  };
  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 bg-white rounded">
            <Card.Body>
              <Card.Title>View Leave</Card.Title>
              <hr />

              <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                colorAccessor="color"
                eventPropGetter={eventStyleGetter}
                style={{ height: "100vh" }}
              />
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const TableOffCanvance = (props) => {
  const { postData, getData, isAllow } = useContext(Context);

  //toggle/
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(`/hrms-masters/leave`);
    await setData(response);
  };
  useEffect(() => {
    getDataAll();
  }, []);

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%", height: "80%", margin: "auto" }}
        placement={"top"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Add Leave</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>All Leave</Card.Title>
              <hr />
              <Container>
                <Table striped bordered hover responsive center>
                  <thead>
                    <tr className="">
                      {visible.col1 && <th className="sr">Sr. No.</th>}
                      {visible.col6 && <th className="sr">Employee Name</th>}
                      {visible.col2 && <th className="tax-name">Leave Type</th>}
                      {visible.col3 && (
                        <th className="tax-name">Reason for absence</th>
                      )}
                      {visible.col5 && <th className="tax-name">Leave Date</th>}
                      {visible.col4 && <th className="tax-name">Status</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {isAllow.includes(77) ? (
                      data?.data?.data?.map((d, index) => {
                        return (
                          <tr className="" key={index}>
                            {visible.col1 && <td>{++index}.</td>}
                            {visible.col6 && <td>{d?.user?.name}</td>}
                            {visible.col2 && <td>{d?.leave_type?.name}</td>}
                            {visible.col3 && <td>{d?.title}</td>}
                            {visible.col5 && (
                              <td>
                                {d?.start} {d?.end ? `To ${d?.end}` : ""}
                              </td>
                            )}

                            {visible.col4 && <td>{d?.status}</td>}
                          </tr>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </tbody>
                </Table>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const Leave = () => {
  const { getData, editStatusData, deleteData, ErrorNotify, isAllow } =
    useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(10);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [show2, setShow2] = useState(0);

  const [changeStatus, setChangeStatus] = useState();

  const getDataAll = async () => {
    const response = await getData(
      `/hrms-masters/leave/all-pending?page=${currentPage}&per_page=${perPage}&term=${search?.value}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
  };
  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, show, show1]);

  const [showModal, setShowModal] = useState(false);
  const ChangeStatus = async (id, status) => {
    const response = await editStatusData(`/hrms-masters/leave/${id}`, {
      status: status,
    });

    if (response.success) {
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 1000);
    }
    setChangeStatus(response);
  };

  // Delete module.....................................................
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id = 1) => {
    setShowEdit(id);
  };

  const handleClose2 = () => setShow2(0);
  const handleShow2 = () => {
    setShow2(1);
  };

  const DeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(
        `/hrms-masters/leave/${recordToDeleteId}`
      );
      await ErrorNotify(recordToDeleteName);
      setChangeStatus(response);
      setRecordToDeleteId(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
  };

  //toggle/
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [users, setUsers] = useState([]);

  const GetAllUsers = async () => {
    const response = await getData("/masters/allusers");

    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.userid,
          name: "user_id",
          label: data.user.name,
        });
      });
    }
    setUsers(option);
  };

  useEffect(() => {
    GetAllUsers();
  }, []);
  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Leave"} link={"/hrms-masters/leave"} />
        <section className="AdvanceDashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <section className="Tabels tab-radio tab-radio mt-5">
              <div className="">
                <div className="row">
                  <div className="d-flex" style={{ justifyContent: "end" }}>
                    <div className="add me-3">
                      {isAllow.includes(78) ? (
                        <Link type="button" className="btn btn-add pe-3">
                          <div onClick={() => handleShow2()}>
                            <img className="plus me-2 ms-0" alt="" />
                            All Leaves
                          </div>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="add me-3">
                      {isAllow.includes(78) ? (
                        <Link type="button" className="btn btn-add pe-3">
                          <div onClick={() => handleShow1()}>
                            <img className="plus me-2 ms-0" alt="" />
                            Calender View
                          </div>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="add me-3">
                      {isAllow.includes(78) ? (
                        <Link type="button" className="btn btn-add pe-3">
                          <div onClick={() => handleShow()}>
                            <img className="plus me-2 ms-0" alt="" />
                            Add Leave
                          </div>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="border-line mt-3"></div>
                  <div className="row mt-4">
                    <div className="  col-lg-8  col-md-8 col-12">
                      <div className="d-flex justify-content-start">
                        <div className=" me-3">
                          <Select
                            name="user_id"
                            className="custom-select"
                            onChange={(e) => {
                              setSearch(e);
                            }}
                            placeholder="users"
                            value={search}
                            options={users}
                          />
                        </div>
                        <div className="Search me-3">
                          <button
                            type="button"
                            onClick={getDataAll}
                            className="btn btn-search"
                          >
                            <img src={search1} className="search" alt="" />
                          </button>
                        </div>

                        <div className="Search-1">
                          <button
                            type="button"
                            onClick={() => {
                              setSearch("");
                              setReset(!reset);
                            }}
                            className="btn btn-reset"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-line mt-3"></div>
                <div className="row mt-3">
                  <div className="data table-responsive">
                    <Row>
                      {data?.data?.data?.map((data, index) => (
                        <Col md={3} className="mb-2" key={index}>
                          <Card>
                            <Card.Body>
                              <p className="m-0">
                                <b>Name :</b> {data?.user?.name}
                              </p>

                              {data?.duration === "Single" ||
                              data?.duration === "Half Day" ? (
                                <p className="m-0">
                                  <b>Date:</b> {data?.start}
                                </p>
                              ) : (
                                <>
                                  <p className="m-0">
                                    <b>Start Date :</b> {data?.start}
                                  </p>
                                  <p className="m-0">
                                    <b>End Date :</b> {data?.end}
                                  </p>
                                </>
                              )}

                              <p className="m-0">
                                <b>Leave Type :</b> {data?.leave_type?.name}
                              </p>

                              <p className="m-0">
                                <b>Duration :</b> {data?.duration}
                              </p>

                              <p className="m-0">
                                <b>Reason :</b> {data?.title}
                              </p>
                            </Card.Body>

                            <Card.Footer>
                              <Col
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                }}
                              >
                                <Button
                                  variant="outline-success"
                                  onClick={() => {
                                    ChangeStatus(data?.id, "Approved");
                                  }}
                                >
                                  Accept
                                </Button>
                                <Button
                                  variant="outline-danger"
                                  className="ms-3"
                                  onClick={() => {
                                    ChangeStatus(data?.id, "Rejected");
                                  }}
                                >
                                  Reject
                                </Button>
                              </Col>
                            </Card.Footer>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      {show2 ? (
        <TableOffCanvance
          handleClose={handleClose2}
          setShow={setShow2}
          show={show2}
        />
      ) : (
        ""
      )}

      <Toaster position="top-right" />

      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  {/* <img src={circle} className="circle-img mb-2" alt="" /> */}
                </div>
                <h1 className="add-success text-center">
                  Leave Updated Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal Delete --> */}
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br />
                  Dependent Data Also Be Deleted And
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button className="btn btn-yes me-2" onClick={DeleteRecord}>
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Leave;
