import React, { useState } from 'react'
import '../../CommonCss/CommonCss.css'
import { Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap'
import { faArrowUpWideShort, faMagnifyingGlass, faPenToSquare, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EditModal from './AssetsModal/EditModal'
import DeleteModal from './AssetsModal/DeleteModal'


const DashboardAssets = () => {
    const [addmodalShow, setAddModalShow] = useState(false);
    const [editmodalShow, setEditModalShow] = useState(false);
    const [deletemodalShow, setDeleteModalShow] = useState(false);
    return (
        <section className='main-common-class-hrms'>
            <div className='white-bggg mt-3'>
                <Row>
                    <Col xxl={8} xl={6} lg={6} md={12} sm={12} xs={12}>
                        <p className='main-table-head'>Assets</p>
                    </Col>
                    <Col xxl={4} xl={6} lg={6} md={12} sm={12} xs={12}>
                        <div className=' d-md-flex justify-content-lg-end'>
                           
                            <Button
                                className="custom-apply-leaves-btn"
                            >
                                Column Selection
                                <span className="icon-container">
                                    <FontAwesomeIcon icon={faArrowUpWideShort} />
                                </span>
                            </Button>
                        </div>
                    </Col>
                </Row>
                <div className='grey-bggg'>
                    <Row>
                        <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                            <div class="show-entries mt-2">
                                <label for="entries">Show</label>
                                <select id="entries" name="entries">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                <label for="entries" className='ms-2'>entries:</label>
                            </div>
                        </Col>
                        <Col xxl={4} xl={4} lg={3} md={6} sm={12} xs={12}>
                            <div className='text-lg-end'>
                                <p className='showing-txttt mt-2'>Showing 1to 5 Of 5 Entries</p>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={6} sm={6} xs={12}>
                            <div className='d-flex'>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
                                    <Form.Control
                                        placeholder="Search Here"
                                        aria-label="Search Here"
                                        aria-describedby="basic-addon1"
                                    />
                                </InputGroup>
                            </div>
                        </Col>
                        <Col xxl={2} xl={2} lg={2} md={3} sm={6} xs={12}>
                            <div className='text-end'>
                                <button className='reset-btnn'>Reset</button>
                            </div>

                        </Col>
                    </Row>
                </div>
                <div className='common-table-hrms'>
                    <Table striped bordered responsive >
                        <thead>
                            <tr>
                                <th>Sr.No</th>
                                <th>Employee Id</th>
                                <th>Name</th>
                                <th>Designation</th>
                                <th>Assigned Assets</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>01.</td>
                                <td>0012</td>
                                <td>
                                    komal nathe
                                </td>
                                <td>
                                    Frontend Devloper
                                </td>
                                <td>
                                    Laptop
                                </td>
                                <td>
                                    <Form>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label="Active"
                                        />
                                    </Form>
                                </td>
                                <td>
                                    <div>
                                        <FontAwesomeIcon icon={faPenToSquare} onClick={() => setEditModalShow(true)} className='table-iconnnn ' />
                                        <FontAwesomeIcon icon={faTrash} onClick={() => setDeleteModalShow(true)} className='table-iconnnn ms-2' />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>01.</td>
                                <td>0012</td>
                                <td>
                                    komal nathe
                                </td>
                                <td>
                                    Frontend Devloper
                                </td>
                                <td>
                                    Laptop
                                </td>
                                <td>
                                    <Form>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label="Active"
                                        />
                                    </Form>
                                </td>
                                <td>
                                    <div>
                                        <FontAwesomeIcon icon={faPenToSquare} onClick={() => setEditModalShow(true)} className='table-iconnnn ' />
                                        <FontAwesomeIcon icon={faTrash} onClick={() => setDeleteModalShow(true)} className='table-iconnnn ms-2' />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>01.</td>
                                <td>0012</td>
                                <td>
                                    komal nathe
                                </td>
                                <td>
                                    Frontend Devloper
                                </td>
                                <td>
                                    Laptop
                                </td>
                                <td>
                                    <Form>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label="Active"
                                        />
                                    </Form>
                                </td>
                                <td>
                                    <div>
                                        <FontAwesomeIcon icon={faPenToSquare} onClick={() => setEditModalShow(true)} className='table-iconnnn ' />
                                        <FontAwesomeIcon icon={faTrash} onClick={() => setDeleteModalShow(true)} className='table-iconnnn ms-2' />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
            <EditModal
                show={editmodalShow}
                onHide={() => setEditModalShow(false)}
            />
            <DeleteModal
                show={deletemodalShow}
                onHide={() => setDeleteModalShow(false)}
            />
        </section>

    )
}

export default DashboardAssets