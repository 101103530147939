import React, { useContext, useEffect, useState } from "react";
import "./Hsidebar.css";
import { Accordion } from "react-bootstrap";
import {
  faHandHoldingDollar,
  faReceipt,
  faTableCellsLarge,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import { Context } from "../../../utils/context";

const Hsidebar = () => {
  const { isAllow } = useContext(Context);
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  }, [location.pathname]);

  const [active, setActive] = useState("0");

  const [activeAccordionItem, setActiveAccordionItem] = useState(null);
  const [activeInnerListItem, setActiveInnerListItem] = useState(null);

  const handleAccordionClick = (itemKey) => {
    if (active == itemKey) {
      setActive(null);
    } else {
      setActiveAccordionItem(itemKey === activeAccordionItem ? null : itemKey);
      setIsOpen(true);
    }
  };

  const handleInnerListItemClick = (itemId) => {
    setActiveInnerListItem(itemId === activeInnerListItem ? null : itemId);
  };

  const [isCollapsed, setIsCollapsed] = useState(false);
  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };
  return (
    <section className={`hrms-sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Link to="/hrms-admin/hrms-dashboard">
            <Accordion.Header
              onClick={isCollapsed ? toggleSidebar : null}
              className="dashboard-btn"
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/hrms-dashboard/Image/icon/dashboard (2).png"
                }
                className="main-accordian-icon-img"
              />{" "}
              {!isCollapsed && "Dashboard"}
            </Accordion.Header>
          </Link>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header onClick={isCollapsed ? toggleSidebar : null}>
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/hrms-dashboard/Image/icon/hr-management.png"
              }
              className="main-accordian-icon-img"
            />{" "}
            {!isCollapsed && "HRMS Setup"}
          </Accordion.Header>
          <Accordion.Body>
            {/* nested accordian */}
            <div className="sub-accordian-main ms-3">
              <Accordion className="sub-accordian">
                <Accordion.Item eventKey="00">
                  <Link to="/hrms-admin/hrms-department">
                    <Accordion.Header>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/hrms-dashboard/Image/icon/1.png"
                        }
                        className="accordian-icon-img"
                      />
                      Department
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
                <Accordion.Item eventKey="01">
                  <Link to="/hrms-admin/hrms-designation">
                    <Accordion.Header>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/hrms-dashboard/Image/icon/2.png"
                        }
                        className="accordian-icon-img"
                      />
                      Designation
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
                <Accordion.Item eventKey="02">
                  <Link to="/hrms-admin/hrms-skills">
                    <Accordion.Header>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/hrms-dashboard/Image/icon/3.png"
                        }
                        className="accordian-icon-img"
                      />
                      Skills
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
                <Accordion.Item eventKey="03">
                  <Link to="/hrms-admin/hrms-calendar">
                    <Accordion.Header>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/hrms-dashboard/Image/icon/4.png"
                        }
                        className="accordian-icon-img"
                      />
                      Calender
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
                <Accordion.Item eventKey="04">
                  <Link
                    to="/hrms-admin/hrms-leave-type"
                    //   className="text-decoration-none"
                  >
                    <Accordion.Header>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/hrms-dashboard/Image/icon/3.png"
                        }
                        className="accordian-icon-img"
                      />{" "}
                      <div
                        className={`${
                          isOpen
                            ? headerText.includes("/hrms-admin/hrms-leave-type")
                              ? "active block"
                              : "none"
                            : "none"
                        }`}
                        onClick={() =>
                          handleInnerListItemClick("hrms-admin/hrms-leave-type")
                        }
                      ></div>
                      Leave Type
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
                {/* <Accordion.Item eventKey="08">
                  <Link
                    to="/hrms-admin/hrms-leave"
                    //   className="text-decoration-none"
                  >
                    <Accordion.Header>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/hrms-dashboard/Image/icon/3.png"
                        }
                        className="accordian-icon-img"
                      />{" "}
                      <div
                        className={`${
                          isOpen
                            ? headerText.includes("/hrms-admin/hrms-leave")
                              ? "active block"
                              : "none"
                            : "none"
                        }`}
                        onClick={() =>
                          handleInnerListItemClick("hrms-admin/hrms-leave")
                        }
                      ></div>
                      Leave
                    </Accordion.Header>
                  </Link>
                </Accordion.Item> */}
                <Accordion.Item eventKey="05">
                  <Link to="/hrms-admin/hrms-employee-type">
                    {" "}
                    <Accordion.Header>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/hrms-dashboard/Image/icon/6.png"
                        }
                        className="accordian-icon-img"
                      />
                      Employee Type
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
                <Accordion.Item eventKey="06">
                  <Link to="/hrms-admin/hrms-setup-assets">
                    {" "}
                    <Accordion.Header>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/hrms-dashboard/Image/icon/7.png"
                        }
                        className="accordian-icon-img"
                      />
                      Assets
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
                <Accordion.Item eventKey="07">
                  <Link to="/hrms-admin/hrms-setup-shifts">
                    {" "}
                    <Accordion.Header>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/hrms-dashboard/Image/icon/8.png"
                        }
                        className="accordian-icon-img"
                      />
                      Shift
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>{" "}
                <Accordion.Item eventKey="08">
                  <Link to="/hrms-admin/hrms-setup-qualification">
                    {" "}
                    <Accordion.Header>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/hrms-dashboard/Image/icon/8.png"
                        }
                        className="accordian-icon-img"
                      />
                      Qualification
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>{" "}
                <Accordion.Item eventKey="09">
                  <Link to="/hrms-admin/hrms-setup-university">
                    <Accordion.Header>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/hrms-dashboard/Image/icon/8.png"
                        }
                        className="accordian-icon-img"
                      />
                      University
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>{" "}
                <Accordion.Item eventKey="10">
                  <Link to="/hrms-admin/hrms-setup-course">
                    <Accordion.Header>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/hrms-dashboard/Image/icon/8.png"
                        }
                        className="accordian-icon-img"
                      />
                      Course
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
              </Accordion>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header onClick={isCollapsed ? toggleSidebar : null}>
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/hrms-dashboard/Image/icon/XMLID_3791_.png"
              }
              className="main-accordian-icon-img"
            />{" "}
            {!isCollapsed && "HRMS"}
          </Accordion.Header>
          <Accordion.Body>
            {/* nested accordian */}
            <div className="sub-accordian-main">
              <Accordion className="sub-accordian">
                <Accordion.Item eventKey="06">
                  <Link to="/hrms-admin/hrms-employee">
                    {" "}
                    <Accordion.Header>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/hrms-dashboard/Image/icon/7.png"
                        }
                        className="accordian-icon-img"
                      />
                      Employee
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
                <Accordion.Item eventKey="07">
                  <Link to="/hrms-admin/hrms-leave">
                    {" "}
                    <Accordion.Header>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/hrms-dashboard/Image/icon/8.png"
                        }
                        className="accordian-icon-img"
                      />
                      Leave
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
                <Accordion.Item eventKey="08">
                  <Link to="/hrms-admin/hrms-attendance">
                    {" "}
                    <Accordion.Header>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/hrms-dashboard/Image/icon/9.png"
                        }
                        className="accordian-icon-img"
                      />
                      Attendance
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
                <Accordion.Item eventKey="09">
                  <Link to="/hrms-admin/hrms-payment-slip">
                    {" "}
                    <Accordion.Header>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/hrms-dashboard/Image/icon/10.png"
                        }
                        className="accordian-icon-img"
                      />
                      Payment Slip
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
                <Accordion.Item eventKey="010">
                  <Link to="/hrms-admin/hrms-assets">
                    {" "}
                    <Accordion.Header>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/hrms-dashboard/Image/icon/11.png"
                        }
                        className="accordian-icon-img"
                      />
                      Assets
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
                <Accordion.Item eventKey="011">
                  <Link to="/hrms-admin/hrms-shift">
                    {" "}
                    <Accordion.Header>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/hrms-dashboard/Image/icon/12.png"
                        }
                        className="accordian-icon-img"
                      />
                      Shift
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
              </Accordion>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className={`hrms-sidebar-footer ${isCollapsed ? "collapsed" : ""}`}>
        <div className="toggle-btn" onClick={toggleSidebar}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/assets/hrms-dashboard/Image/icon/arrow.png"
            }
            className="arrow-icon"
          />
        </div>
      </div>
    </section>
  );
};

export default Hsidebar;
