import React, { useContext } from "react";
import "./super-admin-sidebar.css";
import { Accordion, Col, Row } from "react-bootstrap";
// import Accordion from "react-bootstrap/Accordion";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faBoxesStacked,
  faGear,
  faHouse,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";

import { Context } from "../../../utils/context";
const Sidebar = () => {
  const { isAllow } = useContext(Context);
  return (
    <>
      <section className="superadminsidebarmain">
        <div className="logo-main">
          <Link to="/super-admin/dashboard">
            <img
              className="logoimg"
              src={
                process.env.PUBLIC_URL +
                "/assets/complaint-management/LOGO_1.png"
              }
            />
          </Link>
        </div>

        <div className="sidebarmenusdiv">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Link className="" to="/super-admin/dashboard">
                <Accordion.Header className="removeboticon">
                  <span className="iconwidth">
                    <FontAwesomeIcon className="iconfafa" icon={faHouse} />
                  </span>{" "}
                  <span>Dashboard</span>
                </Accordion.Header>
              </Link>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <span className="iconwidth">
                  <FontAwesomeIcon className="iconfafa" icon={faUser} />
                </span>
                <span>Master</span>
              </Accordion.Header>
              <Accordion.Body>
                <div className="innermenus">
                  <div className="menudiv">
                    <Link to="#">Message Charge</Link>
                  </div>
                  <div className="menudiv">
                    <Link to="#">Message Charge2</Link>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <span className="iconwidth">
                  <FontAwesomeIcon className="iconfafa" icon={faUsers} />
                </span>
                <span>Subscriber</span>
              </Accordion.Header>
              <Accordion.Body>
                <div className="innermenus">
                  <div className="menudiv">
                    <Link to="/super-admin/subscriber">Subscriber</Link>
                  </div>
                  <div className="menudiv">
                    <Link to="/super-admin/active-subscriber">Active</Link>
                  </div>
                  <div className="menudiv">
                    <Link to="/super-admin/expired-subscriber">Expired</Link>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </section>
    </>
  );
};

export default Sidebar;
