import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import DownloadPaymentSlip from '../animation/DownloadPaymentSlip.js'
import UploadFile from '../../Attendance/animation/UploadFile.js'



const FileUploadModal = (props) => {
    return (
        <section className='modal-common-class-hrms'>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="add-modal"
            >
                <Modal.Body>
                    <div className='below-bodyy'>
                        <div className='donwload-div d-flex justify-content-center'>
                            <UploadFile/>
                            <h6 className='mt-2'>Sheet Uploaded Successfully!!</h6>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </section>
    )
}

export default FileUploadModal