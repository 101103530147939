import React, { useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import '../../../CommonCss/ModalCommonCss.css'
import FileUploadModal from '../../Payment-Slip/PaymentSlipModal/FileUploadModal';



const UploadModal = (props) => {

    const [fileSelected, setFileSelected] = useState(false);
    const [showLottie, setshowLottie] = useState(false);

    const handleDownloadClick = () => {

        props.onHide()

        setshowLottie(true); // Show the modal

        // Hide modal after 2 seconds
        setTimeout(() => {
            setshowLottie(false);
        }, 2000);
    };


    const handleFileChange = (e) => {
        // Check if a file is selected
        if (e.target.files.length > 0) {
            setFileSelected(true); // Set state to true if a file is selected
        } else {
            setFileSelected(false); // Set state to false if no file is selected
        }
    };

    return (
        <section className='modal-common-class-hrms'>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="add-modal"
            >
                <Modal.Body>
                    <div className='below-bodyy'>
                        <div className="upload-div text-center mt-4">
                            <input
                                type="file"
                                id="file-upload"
                                style={{ display: "none" }} // Hide default input
                                onChange={handleFileChange} // Handle file selection here
                            />
                            <label htmlFor="file-upload">
                                <div className="custom-upload-button">
                                    +
                                </div>
                            </label>
                        </div>
                        <div className='text-center mt-5 mb-5'>
                            <button className='cancel-button me-2' onClick={props.onHide}>Cancel</button>
                            <button
                                onClick={fileSelected ? handleDownloadClick : null}
                                className={fileSelected ? 'active-upload-button' : 'inactive-upload-button'}
                            >
                                Upload Sheet
                            </button>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <FileUploadModal show={showLottie}
                onHide={() => setshowLottie(false)} />
        </section>
    )
}

export default UploadModal