import "../../CommonCss/CommonCss.css";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import {
  faArrowUpWideShort,
  faMagnifyingGlass,
  faPenToSquare,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddModal from "./UniversityModal/AddModal";
import EditModal from "./UniversityModal/EditModal";
import DeleteModal from "./UniversityModal/DeleteModal";
import Dropdown from "react-bootstrap/Dropdown";

import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../CommonCss/CommonCss.css";

import { Context } from "../../../../utils/context";

import { Link, useNavigate, useParams } from "react-router-dom";

const Assets = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    Per_Page_Dropdown,
  } = useContext(Context);

  const [addmodalShow, setAddModalShow] = useState(false);
  const [editmodalShow, setEditModalShow] = useState(0);

  const handleClose1 = () => setEditModalShow(0);
  const handleShow1 = (id) => {
    setEditModalShow(id);
  };
  const [deletemodalShow, setDeleteModalShow] = useState(false);

  const getDataAll = async () => {
    const response = await getData(
      `/hrms-masters/university?page=${currentPage}&per_page=${perPage}&term=${search}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, show, show1, editmodalShow, addmodalShow]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/hrms-masters/university/${id}`);
    setChangeStatus(response);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  //   const handleClose1 = () => setShowEdit(0);
  //   const handleShow1 = (id) => {
  //     setShowEdit(id);
  //   };

  // Select All Functionality
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(
        `/hrms-masters/university/${recordToDeleteId}`
      );
      // notify("error", "Deleted Succefully");

      ErrorNotify(recordToDeleteName);

      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      setChangeStatus(response);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  return (
    <section className="main-common-class-hrms">
      <div className="white-bggg mt-3">
        <Row>
          <Col xxl={7} xl={6} lg={6} md={12} sm={12} xs={12}>
            <p className="main-table-head">University</p>
          </Col>
          <Col xxl={5} xl={6} lg={6} md={12} sm={12} xs={12}>
            <div className=" d-md-flex justify-content-lg-end">
              <Button
                className="custom-apply-leaves-btn me-2"
                onClick={() => setAddModalShow(true)}
              >
                Add University
                <span className="icon-container">
                  <FontAwesomeIcon icon={faPlus} />
                </span>
              </Button>
              <Dropdown>
                <Dropdown.Toggle
                  className="custom-apply-leaves-btn"
                  id="dropdown-basic"
                >
                  Column Selection
                  <span className="icon-container">
                    <FontAwesomeIcon icon={faArrowUpWideShort} />
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        className="dropdown-item"
                        onClick={(event) => toggleColumn(event, "col1")}
                        href="#"
                      >
                        Sr. No.
                        {visible?.col1 ? (
                          <FontAwesomeIcon
                            className="ms-4"
                            icon="fa-solid fa-eye"
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="ms-4"
                            icon="fa-solid fa-eye-slash"
                          />
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        onClick={(event) => toggleColumn(event, "col2")}
                        href="#"
                      >
                        University
                        {visible?.col2 ? (
                          <FontAwesomeIcon
                            className="ms-2"
                            icon="fa-solid fa-eye"
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="ms-2"
                            icon="fa-solid fa-eye-slash"
                          />
                        )}
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="dropdown-item"
                        onClick={(event) => toggleColumn(event, "col3")}
                        href="#"
                      >
                        Status
                        {visible?.col3 ? (
                          <FontAwesomeIcon
                            className="ms-4"
                            icon="fa-solid fa-eye"
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="ms-4"
                            icon="fa-solid fa-eye-slash"
                          />
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        onClick={(event) => toggleColumn(event, "col4")}
                        href="#"
                      >
                        Action
                        {visible?.col4 ? (
                          <FontAwesomeIcon
                            className="ms-4"
                            icon="fa-solid fa-eye"
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="ms-4"
                            icon="fa-solid fa-eye-slash"
                          />
                        )}
                      </Link>
                    </li>
                  </ul>
                  <Dropdown.Item>
                    {" "}
                    <li>
                      <Link
                        className="dropdown-item"
                        onClick={(event) => toggleColumn(event, "col1")}
                        href="#"
                      >
                        Sr. No.
                        {visible?.col1 ? (
                          <FontAwesomeIcon
                            className="ms-4"
                            icon="fa-solid fa-eye"
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="ms-4"
                            icon="fa-solid fa-eye-slash"
                          />
                        )}
                      </Link>
                    </li>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    {" "}
                    <li>
                      <Link
                        className="dropdown-item"
                        onClick={(event) => toggleColumn(event, "col2")}
                        href="#"
                      >
                        University
                        {visible?.col2 ? (
                          <FontAwesomeIcon
                            className="ms-2"
                            icon="fa-solid fa-eye"
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="ms-2"
                            icon="fa-solid fa-eye-slash"
                          />
                        )}
                      </Link>
                    </li>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    {" "}
                    <li>
                      <Link
                        className="dropdown-item"
                        onClick={(event) => toggleColumn(event, "col3")}
                        href="#"
                      >
                        Status
                        {visible?.col3 ? (
                          <FontAwesomeIcon
                            className="ms-4"
                            icon="fa-solid fa-eye"
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="ms-4"
                            icon="fa-solid fa-eye-slash"
                          />
                        )}
                      </Link>
                    </li>
                  </Dropdown.Item>{" "}
                  <Dropdown.Item>
                    {" "}
                    <li>
                      <Link
                        className="dropdown-item"
                        onClick={(event) => toggleColumn(event, "col4")}
                        href="#"
                      >
                        Action
                        {visible?.col4 ? (
                          <FontAwesomeIcon
                            className="ms-4"
                            icon="fa-solid fa-eye"
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="ms-4"
                            icon="fa-solid fa-eye-slash"
                          />
                        )}
                      </Link>
                    </li>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
          <AddModal show={addmodalShow} onHide={() => setAddModalShow(false)} />
        </Row>
        <div className="grey-bggg">
          <Row>
            <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
              <div class="show-entries mt-2">
                <label for="entries">Show</label>
                <select
                  className="form-select form-select-sm"
                  aria-label=".form-select-sm example"
                  onChange={(e) => setperPage(e.target.value)}
                >
                  {option?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <label for="entries" className="ms-2">
                  entries
                </label>
              </div>
            </Col>
            <Col xxl={4} xl={4} lg={3} md={6} sm={12} xs={12}>
              <div className="text-lg-end sowing me-2">
                <p className="showing-txttt mt-2">{`Showing ${Math.min(
                  (currentPage - 1) * perPage + 1
                )} to ${Math.min(
                  currentPage * perPage,
                  data?.data?.totalEntries
                )} of ${data?.data?.totalEntries} entries`}</p>
              </div>
            </Col>
            <Col xxl={3} xl={3} lg={3} md={6} sm={6} xs={12}>
              <div className="d-flex">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Search Here"
                    aria-label="Search Here"
                    aria-describedby="basic-addon1"
                    id=""
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </Col>
            <Col xxl={2} xl={2} lg={2} md={3} sm={6} xs={12}>
              <button
                type="button"
                onClick={getDataAll}
                className="btn btn-search mb-3 me-2"
              >
                Search
              </button>
              <button
                type="button"
                onClick={() => {
                  setSearch("");
                  setReset(!reset);
                }}
                className="btn reset-btnn mb-3"
              >
                Reset
              </button>
            </Col>
          </Row>
        </div>
        <div className="common-table-hrms">
          <Table striped bordered responsive>
            <thead>
              <tr className="">
                {visible.col1 && <th className="sr">Sr. No.</th>}
                {visible.col2 && <th className="tax-name">University</th>}

                {visible.col3 && <th className="tax-name">Status</th>}
                {visible.col4 && <th className="active">Action</th>}
              </tr>
            </thead>
            <tbody>
              {isAllow.includes(73) ? (
                data?.data?.data?.map((d, index) => {
                  return (
                    <tr className="" key={index}>
                      {visible.col1 && <td>{++index}.</td>}
                      {visible.col2 && <td>{d?.name}</td>}

                      {visible.col3 && (
                        <td>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              checked={d.status}
                              onChange={() => {
                                ChangeStatus(d.id);
                              }}
                              id={`flexSwitchCheckDefault${d?.id}`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`flexSwitchCheckDefault${d?.id}`}
                            >
                              {d.status ? "Active" : "Inactive"}
                            </label>
                          </div>
                        </td>
                      )}

                      {visible.col4 && (
                        <td>
                          <div className="d-flex">
                            {isAllow.includes(75) ? (
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                className="table-iconnnn "
                                onClick={() => handleShow1(d?.id)}
                                // onClick={() => handleShow1(d?.id)}
                                type="button"
                              />
                            ) : (
                              // <img src={pen} className="pen" alt="" />

                              <></>
                            )}
                            {isAllow.includes(76) ? (
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="table-iconnnn ms-2"
                                onClick={() => showDeleteRecord(d?.id, d?.name)}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })
              ) : (
                <></>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {editmodalShow ? (
        <EditModal
          show={editmodalShow}
          handleClose={handleClose1}
          setShow={setEditModalShow}
        />
      ) : (
        ""
      )}

      <DeleteModal
        show={showDeleteModal}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteCancel={handleDeleteCancel}
      />
    </section>
  );
};

export default Assets;
