import React, { useState } from 'react'
import { Row } from 'react-bootstrap'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './CalendarHrms.css'

const CalendarHrms = () => {
    const [value, setValue] = useState(new Date());
    return (
        <section className='main-common-class-hrms'>
            <div className='white-bggg mt-3'>
                <p className='main-table-head'>Calendar</p>


                <div className='card-section'>
                    <div className="row">
                        {/* Total Present Card */}
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                            <div className='designed-cards'>
                                <div className='img-holder'>
                                    <img src={process.env.PUBLIC_URL + "/assets/hrms-dashboard/Image/employee-cards/card1.png"} alt="Card 1" />
                                </div>
                                <div className='text-container'>
                                    <p>Events in <br />Years</p>
                                </div>
                                <div className='count-container'>
                                    <p>30</p>
                                </div>
                            </div>
                        </div>

                        {/* Total Absent Card */}
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                            <div className='designed-cards'>
                                <div className='img-holder'>
                                    <img src={process.env.PUBLIC_URL + "/assets/hrms-dashboard/Image/employee-cards/card2.png"} alt="Card 2" />
                                </div>
                                <div className='text-container'>
                                    <p>Total <br />Holidays</p>
                                </div>
                                <div className='count-container'>
                                    <p>50</p>
                                </div>
                            </div>
                        </div>

                        {/* Late Marks Card */}
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                            <div className='designed-cards'>
                                <div className='img-holder'>
                                    <img src={process.env.PUBLIC_URL + "/assets/hrms-dashboard/Image/employee-cards/card3.png"} alt="Card 3" />
                                </div>
                                <div className='text-container'>
                                    <p>Important <br />Event</p>
                                </div>
                                <div className='count-container'>
                                    <p>10</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Row>
                    <Calendar onChange={setValue} value={value} />
                </Row>
            </div>
        </section>
    )
}

export default CalendarHrms