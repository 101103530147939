import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import "./holiday.css";
import plus from "../../../../Components/Admin/assets/icons/a1.png";
import pen from "../../../../Components/Admin/assets/icons/pen.png";
import Select from "react-select";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Toaster } from "react-hot-toast";
import Card from "react-bootstrap/Card";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../../../common/ModalDelete";
import { AddButton, EditButton, DeletButton } from "../../../common/Button";

const localizer = momentLocalizer(moment);

library.add(fas);

const Calander = (props) => {
  const { getData } = useContext(Context);
  const navigate = useNavigate();
  const id = props.show;

  const [events, setEvents] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/hrms-masters/holiday`);
      const MyArray = response?.data?.data?.map((d) => ({
        id: d.id,
        name: d.name,
        start: new Date(d.start),
        end: new Date(d.end).setHours(23, 59, 59, 999),
      }));
      setEvents(MyArray);
    } catch (error) {
      console.error("Error fetching holiday data:", error);
    }
  };

  useEffect(() => {
    getDataAll();
  }, []);

  const eventStyleGetter = (event, start, end, isSelected) => {
    return {
      style: {
        backgroundColor: event?.color,
      },
    };
  };

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 bg-white rounded">
            <Card.Body>
              <Card.Title>All Holiday</Card.Title>
              <hr />

              <Calendar
                localizer={localizer}
                views={["month"]}
                events={events}
                startAccessor="start"
                endAccessor="end"
                titleAccessor="name"
                eventPropGetter={eventStyleGetter}
                style={{ height: "100vh" }}
              />
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const TableOffCanvance = (props) => {
  const { postData, getData, isAllow } = useContext(Context);

  const formatDateString = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(`/hrms-masters/holiday`);
    await setData(response);
  };
  useEffect(() => {
    getDataAll();
  }, []);

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>All Holidays</Card.Title>
              <hr />
              <Container>
                <Table striped bordered hover responsive center>
                  <thead>
                    <tr className="">
                      <th className="sr">Sr. No.</th>
                      <th className="tax-name">Holiday</th>
                      <th className="tax-name">Start</th>
                      <th className="tax-name">End</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.data?.map((d, index) => {
                      return (
                        <tr className="" key={index}>
                          <td>{++index}.</td>
                          <td>{d?.name}</td>
                          <td>{formatDateString(d?.start)}</td>
                          <td>{formatDateString(d?.end)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const Tables = () => {
  const { getData, deleteData, ErrorNotify, isAllow } = useContext(Context);

  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [show2, setShow2] = useState(0);
  const [show3, setShow3] = useState(0);
  const [events, setEvents] = useState([]);
  const [changeStatus, setChangeStatus] = useState();

  const getDataAll = async () => {
    const response = await getData(`/hrms-masters/holiday`);
    let MyArray = [];
    response?.data?.data?.map((d) => {
      const temp = {
        id: d?.id,
        name: d?.name,
        start: d?.start,
        end: d?.end,
      };

      MyArray.push(temp);
    });
    setEvents(MyArray);
  };

  const formatDateString = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 2020 },
    (_, index) => 2021 + index
  );
  useEffect(() => {
    getDataAll();
  }, [changeStatus, show]);

  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  const handleClose2 = () => setShow2(0);
  const handleShow2 = () => {
    setShow2(1);
  };

  const handleClose3 = () => setShow3(0);
  const handleShow3 = () => {
    setShow3(1);
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(
        `/hrms-masters/holiday/${recordToDeleteId}`
      );

      ErrorNotify(recordToDeleteName);

      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      setChangeStatus(response);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  const [months, setMonths] = useState([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);

  const [selectedMonth, setSelectedMonth] = useState("January");

  const handleMonthClick = (month) => {
    setSelectedMonth(month);
  };

  const filteredEvents = events.filter((event) => {
    if (selectedMonth === "All Holidays") {
      return true;
    } else {
      const eventMonth = new Date(event.start).getMonth();
      return eventMonth === months.indexOf(selectedMonth);
    }
  });

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Holiday"} link={"/hrm/holiday"} />
        <section className="AdvanceDashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <section className="Tabels tab-radio tab-radio mt-5">
              <div className="">
                <div className="row">
                  <div className="d-flex justify-content-between">
                    <h3 className="h3 m-0">
                      Holidays List Of{" "}
                      {selectedMonth && (
                        <span className="currentmon">{selectedMonth}</span>
                      )}
                    </h3>
                    <div className="row">
                      <div className="d-flex" style={{ justifyContent: "end" }}>
                        <div className="add me-3">
                          <Link type="button" className="btn btn-add pe-3">
                            <div onClick={() => handleShow3()}>
                              <img
                                src={plus}
                                className="plus me-2 ms-0"
                                alt=""
                              />
                              All Holidays
                            </div>
                          </Link>
                        </div>

                        <div className="add me-3">
                          <Link type="button" className="btn btn-add pe-3">
                            <div onClick={() => handleShow2()}>
                              <img
                                src={plus}
                                className="plus me-2 ms-0"
                                alt=""
                              />
                              Calender View
                            </div>
                          </Link>
                        </div>
                        {isAllow.includes(154) ? (
                          <div className="add me-3">
                            <Link type="button" className="btn btn-add pe-3">
                              <div onClick={() => handleShow()}>
                                <img
                                  src={plus}
                                  className="plus me-2 ms-0"
                                  alt=""
                                />
                                Add Holiday
                              </div>
                            </Link>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2">
                    <div className="card">
                      <div className="p-4">
                        <div className="row g-4">
                          <input
                            type="hidden"
                            className="currentmon"
                            value=""
                          />
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Months</th>
                              </tr>
                            </thead>
                            <tbody>
                              {months?.map((month, index) => (
                                <tr key={index}>
                                  <td>
                                    <a id="link" className="monthslist">
                                      <button
                                        className={`btn btn-months me-2 ${
                                          month === selectedMonth
                                            ? "active"
                                            : ""
                                        }`}
                                        onClick={() => handleMonthClick(month)}
                                      >
                                        <i className="fa fa-solid fa-calendar-check"></i>{" "}
                                        {month}
                                      </button>
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-10">
                    <div className="card">
                      <div className="p-4 text-center">
                        {selectedMonth && (
                          <p className="h3 m-0">
                            <span className="currentmon">{selectedMonth}</span>
                          </p>
                        )}
                        <div className="row g-4 customselected mt-2">
                          <h6>
                            <i className="fa fa-solid fa-calendar-check"></i>
                          </h6>
                        </div>
                        <div className="row" id="search_data">
                          <table className="table sa-datatables">
                            <thead>
                              <tr>
                                <th
                                  className="w-min"
                                  data-orderable="false"
                                ></th>

                                <th>No</th>
                                <th>Holiday</th>
                                <th>Start</th>
                                <th>End</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {isAllow.includes(153) ? (
                                filteredEvents?.map((data, index) => (
                                  <tr key={index}>
                                    <td></td>
                                    <td>{++index}</td>
                                    <td>{data?.name}</td>
                                    <td>{formatDateString(data?.start)}</td>
                                    <td>{formatDateString(data?.end)}</td>
                                    <td>
                                      <div>
                                        {isAllow.includes(155) ? (
                                          <Button
                                            onClick={() =>
                                              handleShow1(data?.id)
                                            }
                                            type="button"
                                            className="btn btn-primary me-1"
                                          >
                                            <img
                                              src={pen}
                                              className="pen"
                                              alt=""
                                            />
                                          </Button>
                                        ) : (
                                          <></>
                                        )}
                                        {isAllow.includes(156) ? (
                                          <DeletButton
                                            showDeleteRecord={showDeleteRecord}
                                            id={data?.id}
                                            name={data?.name}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <></>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      {show2 ? (
        <Calander handleClose={handleClose2} setShow={setShow2} show={show2} />
      ) : (
        ""
      )}

      {show3 ? (
        <TableOffCanvance
          handleClose={handleClose3}
          setShow={setShow3}
          show={show3}
        />
      ) : (
        ""
      )}
      <ModalDelete
        show={showDeleteModal}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteCancel={handleDeleteCancel}
      />
      <Toaster position="top-right" />
    </>
  );
};

export default Tables;
