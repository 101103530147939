import React, { useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import '../../../CommonCss/ModalCommonCss.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import EditLottieModal from '../../../commonLottie/EditLottieModal'



const EditModal = (props) => {

    const [showLottie, setshowLottie] = useState(false);

    const handleDownloadClick = () => {

        props.onHide()

        setshowLottie(true); // Show the modal

        // Hide modal after 2 seconds
        setTimeout(() => {
            setshowLottie(false);
        }, 2000);
    };


    return (
        <section className='modal-common-class-hrms'>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="add-modal"
            >
                <Modal.Body>
                    <div className='text-center'>
                        <h4 className='modal-title-common'>Edit Shifts</h4>
                    </div>
                    <div className='below-bodyy'>
                        <div>
                            <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Employee Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Employee Name" />
                                </Form.Group>
                            </Form>
                        </div>
                        <div className="row mb-3">
                            {/* Employee Id Input */}
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                <Form.Group controlId="employeeId">
                                    <Form.Label>Employee Id</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Employee Id" />
                                </Form.Group>
                            </div>

                            {/* Department Input */}
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                <Form.Group controlId="department">
                                    <Form.Label>Department</Form.Label>
                                    <div className="d-flex align-items-center">
                                        <Form.Control type="text" placeholder="Enter Department" />
                                        <label className="ms-2 mb-0">
                                            <FontAwesomeIcon className='assign-more' icon={faCirclePlus} />
                                        </label>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                        <div>
                            <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Shifts</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Shifts" />
                                </Form.Group>
                            </Form>
                        </div>
                       
                        <div className='ApplyLeaveModal-duration'>
                            <label className='duration-label'>Shift Time</label>
                            <div className='outer-duration-label d-flex justify-content-between'>
                                {/* Column for "From" Time */}
                                <div className='flex-fill'>
                                    <label className='inner-duration-label'>From</label><br />
                                    <input
                                        type='time'
                                        className="inner-duration-date"
                                        placeholder="from time" // Note: Placeholder won't be visible for time inputs in all browsers
                                    />
                                </div>
                                {/* Column for "To" Time */}
                                <div className='flex-fill'>
                                    <label className='inner-duration-label'>To</label><br />
                                    <input
                                        type='time'
                                        className="inner-duration-date"
                                        placeholder="to time" // Note: Placeholder won't be visible for time inputs in all browsers
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='text-center mt-5 mb-5'>
                            <button className='cancel-button me-2' onClick={props.onHide}>Cancel</button>
                            <button onClick={handleDownloadClick}  className='save-button'>Save</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <EditLottieModal pageTitle="Shift" show={showLottie}
                onHide={() => setshowLottie(false)} />
        </section>
    )
}

export default EditModal