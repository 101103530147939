import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row, } from 'react-bootstrap'
import PersonalDetails from './AllEmployeeDetails/PersonalDetails';
import EducationalDetails from './AllEmployeeDetails/EducationalDetails';
import EmploymentDetails from './AllEmployeeDetails/EmploymentDetails';
import ResidentialAddress from './AllEmployeeDetails/ResidentialAddress'
import PreviousEmployer from './AllEmployeeDetails/PreviousEmployer'
import './AddEmployee.css'
import { Form } from 'react-bootstrap'
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../../utils/context";
import AddQualification from "./addmodals/AddQualification";
import AddUniversity from "./addmodals/AddUniversity";
import AddCourse from "./addmodals/AddCourse";
import AddDepartment from "./addmodals/AddDepartment";
import AddDesignation from "./addmodals/AddDesignation";
import AddedLottieModal from "../../../commonLottie/AddedLottieModal";



const EditEmployee = () => {
    const { postData, getData, Select2Data, IMG_URL } = useContext(Context);

    const { id } = useParams();

    const [showLottie, setShowLottie] = useState({ show: false, msg: "", success: false });

    const [departments, setDepartments] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [qualifications, setQualifications] = useState([]);
    const [universities, setUniversities] = useState([]);
    const [courses, setCourses] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [pincodes, setPincodes] = useState([]);
    const [sifts, setSifts] = useState([]);
    const [employeeTypes, setEmployeTypes] = useState([]);
    const [modalQualification, setModalQualification] = useState(false);
    const [modalUniversity, setModalUniversity] = useState(false);
    const [modalCourse, setModalCourse] = useState(false);
    const [modalDepartment, setModalDepartment] = useState(false);
    const [modalDesignation, setModalDesignation] = useState(false);

    const navigate = useNavigate();

    const {
        control,
        register,
        handleSubmit,
        getValues,
        formState: { errors },
        watch,
        setValue,
        reset
    } = useForm({
        defaultValues: {
            educations: [{}],
            previouses: [{}],
        },
    });


    const { fields: educationFields, append: appendEducation, remove: removeEducation } = useFieldArray({
        control,
        name: "educations",
    });

    const { fields: previousFields, append: appendPrevious, remove: removePrevious } = useFieldArray({
        control,
        name: "previouses",
    });

    console.log(getValues());


    const onSubmit = async (data) => {
        try {
            const DataToSend = new FormData();

            // Prepare user data
            const dataTouser = {
                name: data?.userdata?.name,
                mobile_number: data?.userdata?.mobile_number,
                department_id: data?.userdata?.department_id?.value,
                email: data?.userdata?.email,
                password: data?.userdata?.password,
            };
            DataToSend.append("dataTouser", JSON.stringify(dataTouser)); // Convert to JSON string
            DataToSend.append("image", data?.image[0]);

            // Prepare personal details
            const dataToPersonalDetails = {
                dob: data?.personaldata?.dob,
                personal_email: data?.personaldata?.personal_email,
                gender: data?.personaldata?.gender?.value,
                blood_group: data?.personaldata?.blood_group,
                guardian_name: data?.personaldata?.guardian_name,
                guardian_contact_no: data?.personaldata?.guardian_contact_no,
            };
            DataToSend.append("dataToPersonalDetails", JSON.stringify(dataToPersonalDetails)); // Convert to JSON string

            // Prepare current address
            const dataToCurrentAdd = {
                address: data?.currentadd?.address,
                from_date: data?.currentadd?.from_date,
                to_date: data?.currentadd?.to_date,
                country_id: data?.currentadd?.country_id?.value,
                state_id: data?.currentadd?.state_id?.value,
                city_id: data?.currentadd?.city_id?.value,
                pincode_id: data?.currentadd?.pincode_id?.value,
            };
            DataToSend.append("dataToCurrentAdd", JSON.stringify(dataToCurrentAdd)); // Convert to JSON string

            // Prepare permanent address
            const dataToPermanentAdd = {
                address: data?.permanentadd?.address,
                from_date: data?.permanentadd?.from_date,
                to_date: data?.permanentadd?.to_date,
                country_id: data?.permanentadd?.country_id?.value,
                state_id: data?.permanentadd?.state_id?.value,
                city_id: data?.permanentadd?.city_id?.value,
                pincode_id: data?.permanentadd?.pincode_id?.value,
            };
            DataToSend.append("dataToPermanentAdd", JSON.stringify(dataToPermanentAdd)); // Convert to JSON string

            // Prepare employment data
            const dataToEmployment = {
                employee_id: data?.employementdata?.employee_id,
                // employee_email: data?.employementdata?.employee_email,
                joining_date: data?.employementdata?.joining_date,
                reporting_to: data?.employementdata?.reporting_to,
                from_date: data?.employementdata?.from_date,
                to_date: data?.employementdata?.to_date,
                employee_type_id: data?.employementdata?.employee_type_id?.value,
                shift_id: data?.employementdata?.shift_id?.value,
                department_id: data?.employementdata?.department_id?.value,
                designation_id: data?.employementdata?.designation_id?.value,
            };
            DataToSend.append("dataToEmployment", JSON.stringify(dataToEmployment)); // Convert to JSON string

            // Append educations
            const educations = data?.educations?.map((item) => ({
                passing_year: item?.passing_year,
                percentage: item?.percentage,
                qualification_id: item?.qualification_id?.value,
                university_id: item?.university_id?.value,
                course_id: item?.course_id?.value,
            })) || [];
            DataToSend.append("educations", JSON.stringify(educations)); // Convert to JSON string

            // Append educations
            const previouses = data?.previouses?.map((item) => ({
                employee_id: item?.employee_id,
                company_name: item?.company_name,
                address: item?.address,
                last_drawn_salary: item?.last_drawn_salary,
                reporting_manager_name: item?.reporting_manager_name,
                contact_no: item?.contact_no,
                from_date: item?.from_date,
                to_date: item?.to_date,
                document_type: item?.document_type?.value,
                employee_type_id: item?.employee_type_id?.value,
                department_id: item?.department_id?.value,
                designation_id: item?.designation_id?.value,
            })) || [];
            DataToSend.append("previouses", JSON.stringify(previouses)); // Convert to JSON string

            // Send data
            const response = await postData(`/hrms/employee/${id}`, DataToSend);
            console.log("Response:", response);
            console.log("Data to send:", DataToSend);

            if (response?.success) {
                setShowLottie({ show: true, msg: "Employee Updated Successfully", success: true });
                reset();

            } else {
                setShowLottie({ show: true, msg: response?.message, success: false });
            }
            setTimeout(() => {
                setShowLottie({ show: false, msg: "", success: false });
                navigate("/hrms-admin/hrms-employee", { replace: true })
            }, 3000);
        } catch (error) {
            console.error("Submission error:", error);
        }
    };



    const GetAllDepartments = async () => {
        const response = await getData("/masters/alldepartments");

        if (response?.success) {
            setDepartments(await Select2Data(response?.data, "department_id"));
        }
    };

    const GetAllDesignations = async () => {
        const response = await getData("/masters/alldesignation");

        if (response?.success) {
            setDesignations(await Select2Data(response?.data, "designation_id"));
        }
    };

    const GetAllQualifications = async () => {
        const response = await getData("/masters/allqualification");

        if (response?.success) {
            setQualifications(await Select2Data(response?.data, "qualification_id"));
        }
    };

    const GetAllUniversities = async () => {
        const response = await getData("/masters/alluniversity");

        if (response?.success) {
            setUniversities(await Select2Data(response?.data, "university_id"));
        }
    };

    const GetAllCourses = async () => {
        const response = await getData("/masters/allcourse");

        if (response?.success) {
            setCourses(await Select2Data(response?.data, "course_id"));
        }
    };

    const GetAllSift = async (id) => {
        const response = await getData(`/masters/allshifts`);

        if (response?.success) {
            setSifts(await Select2Data(response?.data, "shift_id"));
        }
    };

    const GetAllEmployeTypes = async (id) => {
        const response = await getData(`/masters/allemployeetypes`);

        if (response?.success) {
            setEmployeTypes(await Select2Data(response?.data, "employee_type_id"));
        }
    };

    const GetAllCountries = async () => {
        const response = await getData("/masters/allcountry");

        if (response?.success) {
            setCountries(await Select2Data(response?.data, "country_id"));
        }
    };
    const GetAllState = async (id) => {
        const response = await getData(`/masters/allstate/${id}`);

        if (response?.success) {
            setStates(await Select2Data(response?.data, "state_id"));
        }
    };
    const GetAllCities = async (id) => {
        const response = await getData(`/masters/allcity/${id}`);

        if (response?.success) {
            setCities(await Select2Data(response?.data, "city_id"));
        }
    };
    const GetAllPincodes = async (id) => {
        const response = await getData(`/masters/allpincode/${id}`);

        if (response?.success) {
            setPincodes(await Select2Data(response?.data, "pincode_id"));
        }
    };

    const GetEditData = async () => {
        console.log("ididid", id);
        const response = await getData(`/hrms/employee/${id}`);
        reset(response?.data)
        if (response.data.educations && response.data.educations.length < 1) {
            appendEducation({});
        }
        if (response.data.previouses && response.data.previouses.length < 1) {
            appendPrevious({});
        }
    }

    useEffect(() => {
        GetAllDepartments();
        GetAllDesignations();
        GetAllQualifications();
        GetAllUniversities();
        GetAllCourses();
        GetAllCountries();
        GetAllSift();
        GetAllEmployeTypes();
        // GetAllState();
        // GetAllCities();
        // GetAllPincodes();
        GetEditData()
    }, []);

    useEffect(() => {
        GetEditData()
    }, [id]);


    return (
        <section className='main-common-class-hrms'>
            <div className='add-employee-table'>
                <div className='white-bggg mt-3'>
                    <p className='main-table-head'><FontAwesomeIcon onClick={() => navigate("/hrms-admin/hrms-employee", { replace: true })}
                        icon={faArrowLeft} className='arrow-iconnn me-3' />Edit Employee</p>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                {/* Personal Details */}
                                <div className='sub-white-bgg'>
                                    <h4 className='grey-txtt'>Personal Details</h4>
                                    <PersonalDetails
                                        getValues={getValues}
                                        register={register}
                                        classNames={classNames}
                                        errors={errors}
                                        watch={watch}
                                        setValue={setValue}
                                        IMG_URL={IMG_URL}
                                        Controller={Controller}
                                        control={control}
                                    />
                                </div>
                                {/* Educational Details */}
                                <div className='sub-white-bgg mt-3'>
                                    <h4 className='grey-txtt'>Educational Details</h4>
                                    <EducationalDetails
                                        getValues={getValues}
                                        register={register}
                                        classNames={classNames}
                                        errors={errors}
                                        educationFields={educationFields}
                                        appendEducation={appendEducation}
                                        removeEducation={removeEducation}
                                        courses={courses}
                                        universities={universities}
                                        qualifications={qualifications}
                                        Controller={Controller}
                                        control={control}
                                        setModalQualification={setModalQualification}
                                        setModalUniversity={setModalUniversity}
                                        setModalCourse={setModalCourse}
                                    />
                                </div>
                                {/* Employment Details */}
                                <div className='sub-white-bgg mt-3'>
                                    <h4 className='grey-txtt'>Employment Details</h4>
                                    <EmploymentDetails
                                        getValues={getValues}
                                        register={register}
                                        classNames={classNames}
                                        errors={errors}
                                        sifts={sifts}
                                        departments={departments}
                                        designations={designations}
                                        employeeTypes={employeeTypes}
                                        Controller={Controller}
                                        control={control}
                                        setModalDepartment={setModalDepartment}
                                        setModalDesignation={setModalDesignation}
                                    />
                                </div>
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                {/* Residential address */}
                                <div className='sub-white-bgg'>
                                    <h4 className='grey-txtt'>Residential address</h4>
                                    <ResidentialAddress
                                        getValues={getValues}
                                        register={register}
                                        classNames={classNames}
                                        errors={errors}
                                        Controller={Controller}
                                        control={control}
                                        countries={countries}
                                        states={states}
                                        cities={cities}
                                        pincodes={pincodes}
                                        GetAllState={GetAllState}
                                        GetAllCities={GetAllCities}
                                        GetAllPincodes={GetAllPincodes}
                                        setValue={setValue}
                                    />
                                </div>
                                {/* Previous Employer */}
                                <div className='sub-white-bgg mt-3'>
                                    <h4 className='grey-txtt'>Previous Employer</h4>
                                    <PreviousEmployer
                                        getValues={getValues}
                                        register={register}
                                        classNames={classNames}
                                        errors={errors}
                                        Controller={Controller}
                                        control={control}
                                        setValue={setValue}
                                        previousFields={previousFields}
                                        appendPrevious={appendPrevious}
                                        removePrevious={removePrevious}
                                        designations={designations}
                                        departments={departments}
                                        employeeTypes={employeeTypes}
                                        setModalDepartment={setModalDepartment}
                                        setModalDesignation={setModalDesignation}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <button type="submit" > Submit</button>
                    </Form>
                    <AddQualification show={modalQualification} onHide={() => { setModalQualification(false); GetAllQualifications(); }} />
                    <AddUniversity show={modalUniversity} onHide={() => { setModalUniversity(false); GetAllUniversities(); }} />
                    <AddCourse show={modalCourse} onHide={() => { setModalCourse(false); GetAllCourses(); }} />
                    <AddDepartment show={modalDepartment} onHide={() => { setModalDepartment(false); GetAllDepartments(); }} />
                    <AddDesignation show={modalDesignation} onHide={() => { setModalDesignation(false); GetAllDesignations(); }} />

                    <AddedLottieModal
                        pageTitle="Designation"
                        message={showLottie.msg}
                        show={showLottie.show}
                        success={showLottie.success}
                        onHide={() => setShowLottie({ show: false, msg: "", success: false })}
                    />

                </div>
            </div>
        </section >
    )
}

export default EditEmployee