import React, { useContext } from "react";
import "./HrmsProfile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../utils/context";
import Cookies from "js-cookie";

const HrmsProfile = () => {
  const navigate = useNavigate();
  const {
    postData,
    signin,
    setSignin,
    usertype,
    setUsertype,
    IMG_URL,
    setUserData,
    userdata,
    getData,
  } = useContext(Context);

  const LogOut = async () => {
    Cookies.remove("it_pulse_security", { path: "/" });
    await setUserData("");
    await setUsertype("");
    await setSignin(false);
    navigate("/");
  };

  return (
    <section className="main-common-class-hrms">
      <div className="white-bggg mt-3">
        <p className="main-table-head">
          <FontAwesomeIcon icon={faArrowLeft} className="arrow-iconnn me-3" />
          Profile
        </p>
        <Row className="d-flex justify-content-center mt-5">
          <Col xl={5} lg={5} md={5} sm={6} xs={12}>
            <div className="cream-bg">
              <div className="text-center">
                <FontAwesomeIcon icon={faCircleUser} className="user-iconnn" />
              </div>
              <div className="mt-4">
                <Row>
                  <Col xl={5} lg={5} md={5} sm={5} xs={12}>
                    <p className="user-title">Name</p>
                  </Col>
                  <Col xl={7} lg={7} md={7} sm={7} xs={12}>
                    <p className="user-subtitle">Komal Nathe</p>
                  </Col>
                </Row>
                <Row>
                  <Col xl={5} lg={5} md={5} sm={5} xs={12}>
                    <p className="user-title">Employee ID</p>
                  </Col>
                  <Col xl={7} lg={7} md={7} sm={7} xs={12}>
                    <p className="user-subtitle">00012</p>
                  </Col>
                </Row>
                <Row>
                  <Col xl={5} lg={5} md={5} sm={5} xs={12}>
                    <p className="user-title">Email Id</p>
                  </Col>
                  <Col xl={7} lg={7} md={7} sm={7} xs={12}>
                    <p className="user-subtitle">komal@itpulse.com</p>
                  </Col>
                </Row>
                <Row>
                  <Col xl={5} lg={5} md={5} sm={5} xs={12}>
                    <p className="user-title">Mobile No</p>
                  </Col>
                  <Col xl={7} lg={7} md={7} sm={7} xs={12}>
                    <p className="user-subtitle">+91 3637485639</p>
                  </Col>
                </Row>
                <Row>
                  <Col xl={5} lg={5} md={5} sm={5} xs={12}>
                    <p className="user-title">Designation</p>
                  </Col>
                  <Col xl={7} lg={7} md={7} sm={7} xs={12}>
                    <p className="user-subtitle">Hr</p>
                  </Col>
                </Row>
                <Row>
                  <Col xl={5} lg={5} md={5} sm={5} xs={12}>
                    <p className="user-title">Department</p>
                  </Col>
                  <Col xl={7} lg={7} md={7} sm={7} xs={12}>
                    <p className="user-subtitle">Management</p>
                  </Col>
                </Row>
                <Row>
                  <Col xl={5} lg={5} md={5} sm={5} xs={12}>
                    <p className="user-title">Joining date</p>
                  </Col>
                  <Col xl={7} lg={7} md={7} sm={7} xs={12}>
                    <p className="user-subtitle">12/10/2018</p>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="text-center mt-3">
              <button type="button" onClick={LogOut} className="logout-btnnnn">
                Logout
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default HrmsProfile;
