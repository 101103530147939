import React, { useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import './HrmsDashboard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HrmsDashboardChart from '../../Hrms-Dashboard-Chart/HrmsDashboardChart'
import HrmsDashboardChartTwo from '../../Hrms-Dashboard-Chart/HrmsDashboardChartTwo'
import ApplyLeaveModal from '../../Hrms-Modals/ApplyLeaveModal'

import Chart from "react-apexcharts";


const HrmsDashboard = () => {

    const [openApplyLeaveModal, setOpenApplyLeaveModal] = useState(false)
    console.log("openApplyLeaveModal", openApplyLeaveModal)

    const [chartOptions, setChartOptions] = useState({
        series: [
            {
                name: "Present",
                data: [5, 10, 8, 32, 6, 9, 9, 7, 4, 10, 3, 5],
            },
            {
                name: "Absent",
                data: [5, 10, 8, 12, 6, 5, 9, 7, 4, 6, 3, 5],
            },
            {
                name: "Late Marks",
                data: [2, 5, 3, 6, 4, 5, 3, 2, 1, 3, 2, 4],
            },
        ],
        chart: {
            height: 350,
            type: "line",
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "straight",
        },
        // title: {
        //     text: "Employee Attendance Trends by Month",
        //     align: "left",
        // },
        grid: {
            row: {
                colors: ["#f3f3f3", "transparent"],
                opacity: 0.5,
            },
        },
        xaxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            labels: {
                show: false, // Hide default labels
            },
        },
        yaxis: {
            // title: {
            //     text: "Count",
            // },
            min: 0,
            max: 50,
            tickAmount: 5,
            labels: {
                formatter: (val) => Math.floor(val),
            },
        },
    });

    const [chartOptions2, setChartOptions2] = useState({
        series: [
            {
                name: "Applied Leaves",
                data: [5, 10, 8, 32, 6, 9, 9, 7, 4, 10, 3, 5],
            },
            {
                name: "Aproved Leaves",
                data: [5, 10, 8, 12, 6, 5, 9, 7, 4, 6, 3, 5],
            },
            {
                name: "Rejected Leaves",
                data: [2, 5, 3, 6, 4, 5, 3, 2, 1, 3, 2, 4],
            },
            {
                name: "Pending Leaves",
                data: [2, 5, 3, 6, 4, 5, 3, 2, 1, 3, 2, 4],
            },
        ],
        chart: {
            height: 350,
            type: "line",
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "straight",
        },
        // title: {
        //     text: "Employee Attendance Trends by Month",
        //     align: "left",
        // },
        grid: {
            row: {
                colors: ["#f3f3f3", "transparent"],
                opacity: 0.5,
            },
        },
        xaxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            labels: {
                show: false, // Hide default labels
            },
        },
        yaxis: {
            // title: {
            //     text: "Count",
            // },
            min: 0,
            max: 50,
            tickAmount: 5,
            labels: {
                formatter: (val) => Math.floor(val),
            },
        },
    });


    // Define colors for each month
    const labelColors = [
        "#FF5733", // January
        "#33FF57", // February
        "#3357FF", // March
    ];

    return (
        <div className='section-hrms-dashboard'>
            <div className="section-hrms-dashboard-div container-fluid">

                <div className='dashboard-heading-div'>
                    <div className="row">
                        <div className="col-lg-12 d-flex justify-content-between  align-items-center dashboard-heading-row-div">
                            <div className='dashboard-heading-text'>
                                <span>Good Morning!</span>
                            </div>
                            <div className='dashboard-heading-button'>
                                <Button
                                    type="button"
                                    onClick={() => setOpenApplyLeaveModal(true)}
                                    id="save-form"
                                    variant="success"
                                    className="custom-apply-leaves-btn"
                                >
                                    Apply Leaves
                                    <span className="icon-container">
                                        <FontAwesomeIcon icon="fa-solid fa-plus" />
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='card-section'>
                    <div className="row">
                        {/* Total Present Card */}
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                            <div className='home-cards'>
                                <div className='img-holder'>
                                    <img src={process.env.PUBLIC_URL + "/assets/hrms-dashboard/Image/card1.png"} alt="Card 1" />
                                </div>
                                <div className='text-container'>
                                    <p>Total <br />Present</p>
                                </div>
                                <div className='count-container'>
                                    <p>50</p>
                                </div>
                            </div>
                        </div>

                        {/* Total Absent Card */}
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                            <div className='home-cards'>
                                <div className='img-holder'>
                                    <img src={process.env.PUBLIC_URL + "/assets/hrms-dashboard/Image/card2.png"} alt="Card 2" />
                                </div>
                                <div className='text-container'>
                                    <p>Total <br />Absent</p>
                                </div>
                                <div className='count-container'>
                                    <p>04</p>
                                </div>
                            </div>
                        </div>

                        {/* Late Marks Card */}
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                            <div className='home-cards'>
                                <div className='img-holder'>
                                    <img src={process.env.PUBLIC_URL + "/assets/hrms-dashboard/Image/card3.png"} alt="Card 3" />
                                </div>
                                <div className='text-container'>
                                    <p>Late <br />Marks</p>
                                </div>
                                <div className='count-container'>
                                    <p>05</p>
                                </div>
                            </div>
                        </div>

                        {/* Total Applied Leaves Card */}
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                            <div className='home-cards'>
                                <div className='img-holder'>
                                    <img src={process.env.PUBLIC_URL + "/assets/hrms-dashboard/Image/card4.png"} alt="Card 4" />
                                </div>
                                <div className='text-container'>
                                    <p>Total Applied <br />Leaves</p>
                                </div>
                                <div className='count-container'>
                                    <p>15</p>
                                </div>
                            </div>
                        </div>


                        {/* Approved Leaves Card */}
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                            <div className='home-cards'>
                                <div className='img-holder'>
                                    <img src={process.env.PUBLIC_URL + "/assets/hrms-dashboard/Image/card5.png"} alt="Card 5" />
                                </div>
                                <div className='text-container'>
                                    <p>Approved <br />Leaves</p>
                                </div>
                                <div className='count-container'>
                                    <p>22</p>
                                </div>
                            </div>
                        </div>

                        {/* Rejected Leaves Card */}
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                            <div className='home-cards'>
                                <div className='img-holder'>
                                    <img src={process.env.PUBLIC_URL + "/assets/hrms-dashboard/Image/card6.png"} alt="Card 6" />
                                </div>
                                <div className='text-container'>
                                    <p>Rejected <br />Leaves</p>
                                </div>
                                <div className='count-container'>
                                    <p>18</p>
                                </div>
                            </div>
                        </div>

                        {/* Pending Leaves Card */}
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                            <div className='home-cards'>
                                <div className='img-holder'>
                                    <img src={process.env.PUBLIC_URL + "/assets/hrms-dashboard/Image/card7.png"} alt="Card 7" />
                                </div>
                                <div className='text-container'>
                                    <p>Pending <br />Leaves</p>
                                </div>
                                <div className='count-container'>
                                    <p>16</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='chart-main-div'>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                            <div className='first-chart-div'>
                                <Chart
                                    options={chartOptions}
                                    series={chartOptions.series}
                                    type={chartOptions.chart.type}
                                    height={chartOptions.chart.height}
                                    className={"chart"}
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                            <div className='second-chart-div'>
                                <Chart
                                    options={chartOptions2}
                                    series={chartOptions2.series}
                                    type={chartOptions2.chart.type}
                                    height={chartOptions2.chart.height}
                                    className={"chart"}
                                />
                            </div>
                        </div>
                    </div>
                </div>



                <ApplyLeaveModal
                    show={openApplyLeaveModal}
                    onHide={() => setOpenApplyLeaveModal(false)} // Set onHide to close modal
                />

            </div>
        </div>

    )
}

export default HrmsDashboard