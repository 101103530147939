import React from 'react'
import { Link } from 'react-router-dom'
import Pagination from 'react-bootstrap/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare,faTrash, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import Select from 'react-select'

const Expired_subscriber = () => {
  return (
    <>
        <section className='maintablesec'>
        <div className='addsection'>
          <Link to="/super-admin/subscriber-add"><button className='addbtnn btn'>Add Subscriber</button></Link>
        </div>


        <div className='searchdatadiv'>
          <Form>
            <div className='row'>
              <div className='col-lg-3 mb-1'>
                <Form.Group className='form-group'>
                  <Form.Control type="search" placeholder="Search name" />
                </Form.Group>
              </div>
              <div className='col-lg-3 mb-1'>
                <Form.Group className='form-group'>
                  <Form.Control type="search" placeholder="Search state" />
                </Form.Group>
              </div>
              <div className='col-lg-3 mb-1'>
                <Form.Group className='form-group'>
                  <Form.Control type="search" placeholder="Search city" />
                </Form.Group>
              </div>
              <div className='col-lg-3 mb-1'>
                <Form.Group className='form-group'>
                  <button className='searchbtnn btn'>Search</button>
                </Form.Group>
              </div>
            </div>
          </Form>

        </div>

        <div className='table-section mt-5'>
          <div className='table-responsive'>
            <table className='table table-bordered table-striped text-center'>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>State</th>
                  <th>City</th>
                  <th>Business Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Suraj Jagtap</td>
                  <td>aditya@gmail.com</td>
                  <td>Maharashtra</td>
                  <td>Pune</td>
                  <td>Transport</td>
                  <td>
                    <Link to="/super-admin/subscriber-edit"><button className='btn editbtn'><FontAwesomeIcon icon={faPenToSquare} /></button></Link>
                    <button className='btn deletbtn'><FontAwesomeIcon icon={faTrash} /></button>
                  </td>                  
                </tr>

                <tr>
                  <td>2</td>
                  <td>Akshay Farate</td>
                  <td>Saurav@gmail.com</td>
                  <td>Maharashtra</td>
                  <td>Pune</td>
                  <td>Transport</td>
                  <td>
                    <Link to="/super-admin/subscriber-edit"><button className='btn editbtn'><FontAwesomeIcon icon={faPenToSquare} /></button></Link>
                    <button className='btn deletbtn'><FontAwesomeIcon icon={faTrash} /></button>
                  </td>                  
                </tr>

                <tr>
                  <td>3</td>
                  <td>Komal Nathe</td>
                  <td>Komal@gmail.com</td>
                  <td>Maharashtra</td>
                  <td>Pune</td>
                  <td>Transport</td>
                  <td>
                    <Link to="/super-admin/subscriber-edit"><button className='btn editbtn'><FontAwesomeIcon icon={faPenToSquare} /></button></Link>
                    <button className='btn deletbtn'><FontAwesomeIcon icon={faTrash} /></button>
                  </td>                  
                </tr>


              </tbody>
            </table>
          </div>

          <div className='paginationdivv text-center mt-3'>
            <Pagination>
              <Pagination.Prev />
              <Pagination.Item active>{1}</Pagination.Item>
              <Pagination.Item>{2}</Pagination.Item>
              <Pagination.Ellipsis />
              <Pagination.Item>{9}</Pagination.Item>
              <Pagination.Item>{10}</Pagination.Item>
              <Pagination.Next />
            </Pagination>
          </div>
        </div>
      </section>
    </>
  )
}

export default Expired_subscriber