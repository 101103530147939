import React, { useContext } from "react";
import { useState, useEffect } from "react";
import '../../CommonCss/CommonCss.css'
import { Button, Col, Dropdown, Form, InputGroup, Row, Table } from 'react-bootstrap'
import { faArrowUpWideShort, faEye, faMagnifyingGlass, faPenToSquare, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DeleteModal from './EmployeeModal/DeleteModal'
import { Link } from 'react-router-dom'
import { Context } from "../../../../utils/context";


const Employee = () => {
    const [deletemodalShow, setDeleteModalShow] = useState(false);

    const { postData, getData, Select2Data, Per_Page_Dropdown, editStatusData, deleteData } = useContext(Context);
    const [data, setData] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setperPage] = useState(5);
    const [search, setSearch] = useState("");
    const [reset, setReset] = useState();
    const [show, setShowAdd] = useState(false);
    const [show1, setShowEdit] = useState(0);
    const [changeStatus, setChangeStatus] = useState();
    const [option, setOption] = useState();

    const [counts, setCounts] = useState();

    const getDataAllCounts = async () => {
        const response = await getData(
            `/hrms/employee/counts`
        );
        await setCounts(response?.data);
    };

    const getDataAll = async () => {
        const response = await getData(
            `/hrms/employee?page=${currentPage}&per_page=${perPage}&term=${search}`
        );
        await setData(response);
        setCurrentPage(response?.data?.currentPage);
        setperPage(response?.data?.per_page);
        setSearch(response?.data?.search_name);
        setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
        // const newData = response?.data?.data;
        // if (newData) {
        //     const newIds = newData.map((d) => d?.id);
        //     setAllChecked(newIds);
        // }
    };

    useEffect(() => {
        getDataAll();
    }, [changeStatus, perPage, reset, search, show, show1]);

    const ChangeStatus = async (id) => {
        const response = await editStatusData(`/hrms/employee/${id}`);
        setChangeStatus(response);
    };

    const DeleteRecord = async (id) => {
        const response = await deleteData(`/hrms/employee/${id}`);
        if (response?.success) {
            setDeleteModalShow(true);
            getDataAll();
        }
    };

    const toggleColumn = (event, columnName) => {
        event.preventDefault();
        setVisibel((prev) => ({
            ...prev,
            [columnName]: !prev[columnName],
        }));
    };

    const [visible, setVisibel] = useState({
        col0: true,
        col1: true,
        col2: true,
        col3: true,
        col4: true,
        col5: true,
        col6: true,
        col7: true,
        col8: true,
    });


    useEffect(() => {
        getDataAll();
        getDataAllCounts();
    }, []);
    return (
        <section className='main-common-class-hrms'>
            <div className='white-bggg mt-3'>
                <Row>
                    <Col xxl={8} xl={6} lg={6} md={12} sm={12} xs={12}>
                        <p className='main-table-head'>Employee </p>
                    </Col>
                    <Col xxl={4} xl={6} lg={6} md={12} sm={12} xs={12}>
                        <div className=' d-md-flex justify-content-lg-end'>
                            <Link to='/hrms-admin/add-employee'>
                                <Button
                                    className="custom-apply-leaves-btn me-2"
                                >
                                    Add Employee
                                    <span className="icon-container">
                                        <FontAwesomeIcon icon={faPlus} />
                                    </span>
                                </Button>
                            </Link>
                            <Dropdown>
                                <Dropdown.Toggle className="custom-apply-leaves-btn" id="dropdown-basic">

                                    Column Selection
                                    <span className="icon-container">
                                        <FontAwesomeIcon icon={faArrowUpWideShort} />
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={(event) => toggleColumn(event, "col1")}
                                    >
                                        Sr.No
                                        {visible?.col1 ? (
                                            <FontAwesomeIcon
                                                className="ms-4"
                                                icon="fa-solid fa-eye"
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                className="ms-4"
                                                icon="fa-solid fa-eye-slash"
                                            />
                                        )}
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                        onClick={(event) => toggleColumn(event, "col2")}
                                    >
                                        Employee Id
                                        {visible?.col2 ? (
                                            <FontAwesomeIcon
                                                className="ms-4"
                                                icon="fa-solid fa-eye"
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                className="ms-4"
                                                icon="fa-solid fa-eye-slash"
                                            />
                                        )}
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                        onClick={(event) => toggleColumn(event, "col3")}
                                    >
                                        Name
                                        {visible?.col3 ? (
                                            <FontAwesomeIcon
                                                className="ms-4"
                                                icon="fa-solid fa-eye"
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                className="ms-4"
                                                icon="fa-solid fa-eye-slash"
                                            />
                                        )}
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                        onClick={(event) => toggleColumn(event, "col4")}
                                    >
                                        Designation
                                        {visible?.col4 ? (
                                            <FontAwesomeIcon
                                                className="ms-4"
                                                icon="fa-solid fa-eye"
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                className="ms-4"
                                                icon="fa-solid fa-eye-slash"
                                            />
                                        )}
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                        onClick={(event) => toggleColumn(event, "col5")}
                                    >
                                        Department
                                        {visible?.col5 ? (
                                            <FontAwesomeIcon
                                                className="ms-4"
                                                icon="fa-solid fa-eye"
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                className="ms-4"
                                                icon="fa-solid fa-eye-slash"
                                            />
                                        )}
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                        onClick={(event) => toggleColumn(event, "col6")}
                                    >
                                        Employee Type
                                        {visible?.col6 ? (
                                            <FontAwesomeIcon
                                                className="ms-4"
                                                icon="fa-solid fa-eye"
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                className="ms-4"
                                                icon="fa-solid fa-eye-slash"
                                            />
                                        )}
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                        onClick={(event) => toggleColumn(event, "col7")}
                                    >
                                        Status
                                        {visible?.col7 ? (
                                            <FontAwesomeIcon
                                                className="ms-4"
                                                icon="fa-solid fa-eye"
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                className="ms-4"
                                                icon="fa-solid fa-eye-slash"
                                            />
                                        )}
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                        onClick={(event) => toggleColumn(event, "col8")}
                                    >
                                        Action
                                        {visible?.col8 ? (
                                            <FontAwesomeIcon
                                                className="ms-4"
                                                icon="fa-solid fa-eye"
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                className="ms-4"
                                                icon="fa-solid fa-eye-slash"
                                            />
                                        )}
                                    </Dropdown.Item>

                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>


                <div className='card-section'>
                    <div className="row">
                        {/* Total Present Card */}
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                            <div className='designed-cards'>
                                <div className='img-holder'>
                                    <img src={process.env.PUBLIC_URL + "/assets/hrms-dashboard/Image/employee-cards/card1.png"} alt="Card 1" />
                                </div>
                                <div className='text-container'>
                                    <p>Total <br />Employee</p>
                                </div>
                                <div className='count-container'>
                                    <p>{counts?.allEmployees}</p>
                                </div>
                            </div>
                        </div>

                        {/* Total Absent Card */}
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                            <div className='designed-cards'>
                                <div className='img-holder'>
                                    <img src={process.env.PUBLIC_URL + "/assets/hrms-dashboard/Image/employee-cards/card2.png"} alt="Card 2" />
                                </div>
                                <div className='text-container'>
                                    <p>On Role <br />Employee</p>
                                </div>
                                <div className='count-container'>
                                    <p>{counts?.onRole}</p>
                                </div>
                            </div>
                        </div>

                        {/* Late Marks Card */}
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                            <div className='designed-cards'>
                                <div className='img-holder'>
                                    <img src={process.env.PUBLIC_URL + "/assets/hrms-dashboard/Image/employee-cards/card3.png"} alt="Card 3" />
                                </div>
                                <div className='text-container'>
                                    <p>Intern <br />Employee</p>
                                </div>
                                <div className='count-container'>
                                    <p>{counts?.onIntern}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='grey-bggg'>
                    <Row>
                        <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                            <div class="show-entries mt-2">
                                <label for="entries">Show</label>
                                <select
                                    className="form-select form-select-sm"
                                    aria-label=".form-select-sm example"
                                    onChange={(e) => setperPage(e.target.value)}
                                >
                                    {option?.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                                <label for="entries" className='ms-2'>entries:</label>
                            </div>
                        </Col>
                        <Col xxl={4} xl={4} lg={3} md={6} sm={12} xs={12}>
                            <div className="text-lg-end sowing me-2">
                                <p className="showing-txttt mt-2">{`Showing ${Math.min(
                                    (currentPage - 1) * perPage + 1
                                )} to ${Math.min(
                                    currentPage * perPage,
                                    data?.data?.totalEntries
                                )} of ${data?.data?.totalEntries} entries`}</p>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={6} sm={6} xs={12}>
                            <div className='d-flex'>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
                                    <Form.Control
                                        placeholder="Search Name Here"
                                        aria-label="Search Here"
                                        aria-describedby="basic-addon1"
                                        id=""
                                        value={search}
                                        onChange={(e) => {
                                            setSearch(e.target.value);
                                        }}
                                    />
                                </InputGroup>
                            </div>
                        </Col>
                        <Col xxl={2} xl={2} lg={2} md={3} sm={6} xs={12}>
                            <div className='text-end'>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setSearch("");
                                        setReset(!reset);
                                    }}
                                    className="btn reset-btnn mb-3"
                                >
                                    Reset
                                </button>
                            </div>

                        </Col>
                    </Row>
                </div>
                <div className='common-table-hrms'>
                    <Table striped bordered responsive >
                        <thead>
                            <tr>
                                {visible.col1 && <th>Sr.No</th>}
                                {visible.col2 && <th>Employee Id</th>}
                                {visible.col3 && <th>Name</th>}
                                {visible.col4 && <th>Designation</th>}
                                {visible.col5 && <th>Department</th>}
                                {visible.col6 && <th>Employee Type</th>}
                                {visible.col7 && <th>Status</th>}
                                {visible.col8 && <th>Action</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {data && data?.data?.data?.map((item, index) =>
                                <tr>
                                    {visible.col1 && <td>{index + 1}</td>}
                                    {visible.col2 && <td>{item?.e_employment_detail?.employee_id}</td>}
                                    {visible.col3 && <td>{item?.name}</td>}
                                    {visible.col4 && <td>{item?.e_employment_detail?.designation?.name}</td>}
                                    {visible.col5 && <td>{item?.e_employment_detail?.department?.name}</td>}
                                    {visible.col6 && <td>{item?.e_employment_detail?.employee_type?.name}</td>}
                                    {visible.col7 &&
                                        <td>
                                            <Form>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    label="Active"
                                                    checked={item.status}
                                                    onChange={() => {
                                                        ChangeStatus(item.id);
                                                    }}
                                                />
                                            </Form>
                                        </td>
                                    }

                                    {visible.col8 &&
                                        <td>
                                            <div>
                                                <Link to={`/hrms-admin/view-employee/${item?.id}`}><FontAwesomeIcon icon={faEye} className='table-iconnnn ' /></Link>
                                                <Link to={`/hrms-admin/edit-employee/${item?.id}`}><FontAwesomeIcon icon={faPenToSquare} className='table-iconnnn ms-2 ' /></Link>
                                                <FontAwesomeIcon icon={faTrash} onClick={() => DeleteRecord(item?.id)} className='table-iconnnn ms-2' />
                                            </div>
                                        </td>
                                    }
                                </tr>
                            )}



                        </tbody>
                    </Table>
                </div>
            </div>

            <DeleteModal
                show={deletemodalShow}
                onHide={() => setDeleteModalShow(false)}
            />
        </section>

    )
}

export default Employee