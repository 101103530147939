import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../sidebar_components/Dashboard/Dashboard";
import Subscriber_Add from "../sidebar_components/subscriber/Add";
import Subscriber_Edit from "../sidebar_components/subscriber/Edit";
import Subscriber_Table from "../sidebar_components/subscriber/Table";

import Active_subscriber from "../sidebar_components/subscriber/subscriber-inner/Active_subscriber";
import Expired_subscriber from "../sidebar_components/subscriber/subscriber-inner/Expired_subscriber";
import PageNotFound from "../../PageNotFound/PageNotFound";

const Super_admin_routes = () => {
  return (
    <>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/subscriber-add" element={<Subscriber_Add />} />
        <Route path="/subscriber-edit/:id" element={<Subscriber_Edit />} />
        <Route path="/subscriber" element={<Subscriber_Table />} />
        <Route path="/active-subscriber" element={<Active_subscriber />} />
        <Route path="/expired-subscriber" element={<Expired_subscriber />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

export default Super_admin_routes;
