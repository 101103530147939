import React, { useState } from 'react'
import '../../CommonCss/CommonCss.css'
import { Button, Col, Dropdown, Form, InputGroup, Row, Table } from 'react-bootstrap'
import { faArrowUpWideShort, faDownload, faMagnifyingGlass, faPenToSquare, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRectangleList } from '@fortawesome/free-regular-svg-icons'
import Select from "react-select";
import UploadModal from './AttendanceModals/UploadModal'



const Attendance = () => {
    const [activeButton, setActiveButton] = useState('Present');
    const [uploadModalShow, setUploadModalShow] = useState(false)

    console.log("activeButton", activeButton)
    return (
        <section className='main-common-class-hrms'>
            <div className='white-bggg mt-3'>
                <Row>
                    <Col xxl={7} xl={4} lg={4} md={12} sm={12} xs={12}>
                        <p className='main-table-head'>Attendance </p>
                    </Col>
                    <Col xxl={5} xl={8} lg={8} md={12} sm={12} xs={12} className='mb-3'>
                        <div className=' d-md-flex justify-content-lg-end'>
                            <Button
                                className="custom-apply-leaves-btn me-2"

                            >
                                Download Sheet
                                <span className="icon-container">
                                    <FontAwesomeIcon icon={faDownload} />
                                </span>
                            </Button>
                            <Button
                                className="custom-apply-leaves-btn me-2"
                                onClick={() => setUploadModalShow(true)}
                            >
                                Upload Sheet
                                <span className="icon-container">
                                    <FontAwesomeIcon icon={faRectangleList} />
                                </span>
                            </Button>
                            <Dropdown>
                                <Dropdown.Toggle className="custom-apply-leaves-btn" id="dropdown-basic">

                                    Column Selection
                                    <span className="icon-container">
                                        <FontAwesomeIcon icon={faArrowUpWideShort} />
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-0">Sr.No</Dropdown.Item>
                                    <Dropdown.Item href="#/action-1">Employee Id</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Name</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Designation</Dropdown.Item>
                                    <Dropdown.Item href="#/action-4">Employee Type</Dropdown.Item>
                                    <Dropdown.Item href="#/action-4">Clock In Time</Dropdown.Item>
                                    <Dropdown.Item href="#/action-5">Status</Dropdown.Item>
                                    <Dropdown.Item href="#/action-6">Action</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    {/* <AddModal
                        show={addmodalShow}
                        onHide={() => setAddModalShow(false)}
                    /> */}
                </Row>


                <div className='card-section'>
                    <div className="row">
                        {/* Total Present Card */}
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                            <div className='designed-cards'>
                                <div className='img-holder'>
                                    <img src={process.env.PUBLIC_URL + "/assets/hrms-dashboard/Image/attendance-cards/card1.png"} alt="Card 1" />
                                </div>
                                <div className='text-container'>
                                    <p>Total <br />Present</p>
                                </div>
                                <div className='count-container'>
                                    <p>50</p>
                                </div>
                            </div>
                        </div>

                        {/* Total Absent Card */}
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                            <div className='designed-cards'>
                                <div className='img-holder'>
                                    <img src={process.env.PUBLIC_URL + "/assets/hrms-dashboard/Image/attendance-cards/card2.png"} alt="Card 2" />
                                </div>
                                <div className='text-container'>
                                    <p>Total <br />Absent</p>
                                </div>
                                <div className='count-container'>
                                    <p>04</p>
                                </div>
                            </div>
                        </div>

                        {/* Late Marks Card */}
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                            <div className='designed-cards'>
                                <div className='img-holder'>
                                    <img src={process.env.PUBLIC_URL + "/assets/hrms-dashboard/Image/attendance-cards/card3.png"} alt="Card 3" />
                                </div>
                                <div className='text-container'>
                                    <p>Late <br />Marks</p>
                                </div>
                                <div className='count-container'>
                                    <p>05</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='grey-bggg'>
                    <Row className="align-items-center">
                        {/* Show Entries Dropdown */}
                        <Col xxl={2} xl={2} lg={3} md={4} sm={6} xs={12}>
                            <div className="show-entries mt-2">
                                <label htmlFor="entries">Show</label>
                                <select id="entries" name="entries" className="mx-2">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                <label htmlFor="entries" className="ms-2">entries</label>
                            </div>
                        </Col>

                        {/* Showing Entries Text */}
                        <Col xxl={2} xl={4} lg={4} md={4} sm={6} xs={12} className="text-lg-end">
                            <p className="showing-txttt mt-2">Showing 1 to 5 Of 5 Entries</p>
                        </Col>

                        {/* Search Box */}
                        <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={12}>
                            <div className="d-flex">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">
                                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                                    </InputGroup.Text>
                                    <Form.Control
                                        placeholder="Search Here"
                                        aria-label="Search Here"
                                        aria-describedby="basic-addon1"
                                    />
                                </InputGroup>
                            </div>
                        </Col>

                        {/* Status Buttons */}
                        <Col xxl={3} xl={4} lg={5} md={5} sm={6} xs={12}>
                            <div className='button-container d-flex justify-content-around mb-3 '>
                                <button
                                    className={activeButton === 'Present' ? 'active-btn' : 'inactive-btn'}
                                    onClick={() => setActiveButton('Present')}
                                >
                                    Present
                                </button>

                                <button
                                    className={activeButton === 'Absent' ? 'active-btn' : 'inactive-btn'}
                                    onClick={() => setActiveButton('Absent')}
                                >
                                    Absent
                                </button>

                                <button
                                    className={activeButton === 'Late Mark' ? 'active-btn' : 'inactive-btn'}
                                    onClick={() => setActiveButton('Late Mark')}
                                >
                                    Late Mark
                                </button>
                            </div>
                        </Col>

                        {/* Reset Button */}
                        <Col xxl={2} xl={2} lg={2} md={2} sm={6} xs={12} className="text-end mb-3">
                            <button className="reset-btnn">Reset</button>
                        </Col>
                    </Row>
                </div>

                <div className='red-bggg'>
                    <Row className="align-items-center">
                        {/* Column for the Select element */}
                        <Col xxl={2} xl={3} lg={4} md={2} sm={3} xs={12}>
                            <Select className='select-input' /> {/* Ensure Select is responsive */}
                        </Col>

                        {/* Column for the Date inputs */}
                        <Col xxl={10} xl={9} lg={8} md={10} sm={9} xs={12} className="text-lg-end mt-2 mt-md-0">
                            <div className="d-flex date-input-div">
                                <input type="date" className="me-2 first-date-input" /> {/* Margin between the inputs */}
                                <input type="date" className='second-date-input' />
                            </div>
                        </Col>
                    </Row>
                </div>




                <div className='common-table-hrms'>
                    <Table striped bordered responsive >
                        <thead>
                            <tr>
                                <th>Sr.No</th>
                                <th>Employee Id</th>
                                <th>Name</th>
                                <th>Designation</th>
                                <th>Employee Type</th>
                                <th>Status</th>
                                <th>Clock In Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>01.</td>
                                <td>0012</td>
                                <td>Komal Nathe</td>
                                <td>Frontend Devloper</td>
                                <td>Intern</td>
                                <td>
                                    Present
                                </td>
                                <td>
                                    9.30 AM
                                </td>
                            </tr>
                            <tr>
                                <td>01.</td>
                                <td>0012</td>
                                <td>Komal Nathe</td>
                                <td>Frontend Devloper</td>
                                <td>Intern</td>
                                <td>
                                    Present
                                </td>
                                <td>
                                    9.30 AM
                                </td>
                            </tr>
                            <tr>
                                <td>01.</td>
                                <td>0012</td>
                                <td>Komal Nathe</td>
                                <td>Frontend Devloper</td>
                                <td>Intern</td>
                                <td>
                                    Present
                                </td>
                                <td>
                                    9.30 AM
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
            {/* <EditModal
                show={editmodalShow}
                onHide={() => setEditModalShow(false)}
            />
            <DeleteModal
                show={deletemodalShow}
                onHide={() => setDeleteModalShow(false)}
            /> */}
            <UploadModal
                show={uploadModalShow}
                onHide={() => setUploadModalShow(false)}
            />
        </section>



    )
}

export default Attendance