import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';

const PreviousEmployer = ({
    getValues,
    register,
    classNames,
    errors,
    Controller,
    control,
    setValue,
    removePrevious,
    appendPrevious,
    previousFields,
    designations,
    departments,
    employeeTypes,
    disabled,
    setModalDesignation,
    setModalDepartment
}) => {
    const [employers, setEmployers] = useState([{ id: Date.now() }]);

    const addEmployer = () => {
        setEmployers([...employers, { id: Date.now() }]);
    };

    const documents = [
        { value: "Experience Certificate", name: "document_type", label: "Experience Certificate" },
        { value: "Payslips", name: "document_type", label: "Payslips" },
        { value: "Relieving Letter", name: "document_type", label: "Relieving Letter" },
        { value: "Any Other", name: "document_type", label: "Any Other" },
    ]

    return (
        <div>
            <div className='below-formmmmm'>

                <Form >
                    {previousFields?.map((emp, index) =>
                        <Row key={emp.id}>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Form.Group className="mb-4" controlId={`companyName-${index}`}>
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                        disabled={disabled}
                                        type="text"
                                        name={`previouses.${index}.company_name`}
                                        placeholder="Comapny Name"
                                        className={classNames("", {
                                            "is-invalid": errors?.previouses?.[index]?.company_name, // Adjusted error checking
                                        })}
                                        {...register(`previouses.${index}.company_name`, {
                                            required: "Comapny name is required",
                                        })}
                                    />
                                    {errors?.previouses?.[index]?.company_name && (
                                        <div className="invalid-feedback">
                                            {errors.previouses[index].company_name.message} {/* Display the error message */}
                                        </div>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Form.Group className="mb-4" controlId={`address-${index}`}>
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        disabled={disabled}
                                        type="text"
                                        name={`previouses.${index}.address`}
                                        placeholder="Comapny Address"
                                        className={classNames("", {
                                            "is-invalid": errors?.previouses?.[index]?.address, // Adjusted error checking
                                        })}
                                        {...register(`previouses.${index}.address`, {
                                            required: "Comapny Address is required",
                                        })}
                                    />
                                    {errors?.previouses?.[index]?.address && (
                                        <div className="invalid-feedback">
                                            {errors.previouses[index].address.message} {/* Display the error message */}
                                        </div>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Form.Group className="mb-4" controlId={`employeeId-${index}`}>
                                    <Form.Label>Employee Id</Form.Label>
                                    <Form.Control
                                        disabled={disabled}
                                        type="text"
                                        name={`previouses.${index}.employee_id`}
                                        placeholder="Comapny Employee Id"
                                        className={classNames("", {
                                            "is-invalid": errors?.previouses?.[index]?.employee_id, // Adjusted error checking
                                        })}
                                        {...register(`previouses.${index}.employee_id`, {
                                            required: "Comapny Employee Id is required",
                                        })}
                                    />
                                    {errors?.previouses?.[index]?.employee_id && (
                                        <div className="invalid-feedback">
                                            {errors.previouses[index].employee_id.message} {/* Display the error message */}
                                        </div>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Form.Group className="mb-4" controlId={`previouses-${index}`}>
                                    <Form.Label>Designation</Form.Label>
                                    <div className='d-flex'>
                                        <Controller
                                            name={`previouses.${index}.designation_id`} // name of the field
                                            control={control}
                                            rules={{
                                                required: "Select Designation",
                                            }}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <Select
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            borderColor: errors?.previouses?.[index]?.designation_id ? "red" : baseStyles.borderColor,
                                                        }),
                                                    }}
                                                    // {...field} 
                                                    options={designations}
                                                    onChange={(selectedValue) => {
                                                        onChange(selectedValue);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    ref={ref}
                                                    isDisabled={disabled}
                                                />
                                            )}
                                        />
                                        {!disabled &&
                                            <button className='add-button' type='button' width="10"
                                                onClick={() => setModalDesignation(true)}
                                            ><FontAwesomeIcon icon={faPlus} width="10" /></button>
                                        }
                                    </div>
                                    {errors?.previouses?.[index]?.designation_id && (
                                        <span className="text-danger">
                                            {errors?.previouses?.[index]?.designation_id.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Form.Group className="mb-4" controlId={`department-${index}`}>
                                    <Form.Label>Department</Form.Label>
                                    <div className='d-flex'>
                                        <Controller
                                            name={`previouses.${index}.department_id`} // name of the field
                                            control={control}
                                            rules={{
                                                required: "Select Department",
                                            }}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <Select
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            borderColor: errors?.previouses?.[index]?.department_id ? "red" : baseStyles.borderColor,
                                                        }),
                                                    }}
                                                    // {...field} 
                                                    options={departments}
                                                    onChange={(selectedValue) => {
                                                        onChange(selectedValue);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    ref={ref}
                                                    isDisabled={disabled}
                                                />
                                            )}
                                        />
                                        {!disabled &&
                                            <button className='add-button' type='button' width="10"
                                                onClick={() => setModalDepartment(true)}
                                            ><FontAwesomeIcon icon={faPlus} width="10" /></button>
                                        }
                                    </div>
                                    {errors?.previouses?.[index]?.department_id && (
                                        <span className="text-danger">
                                            {errors?.previouses?.[index]?.department_id.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Form.Group className="mb-4" controlId={`lastSalary-${index}`}>
                                    <Form.Label>Last Drawn Salary</Form.Label>
                                    <Form.Control
                                        disabled={disabled}
                                        type="text"
                                        name={`previouses.${index}.last_drawn_salary`}
                                        placeholder="Enter in LPA"
                                        className={classNames("", {
                                            "is-invalid": errors?.previouses?.[index]?.last_drawn_salary, // Adjusted error checking
                                        })}
                                        {...register(`previouses.${index}.last_drawn_salary`, {
                                            required: "Enter Amount",
                                            pattern: {
                                                value: /^(?!0\d)\d+(\.\d{1,2})?$/, // Regex for valid numeric input (with optional decimal up to two places)
                                                message: "Please enter a valid amount (e.g., 10000 or 10000.50).",
                                            },
                                        })}
                                        onKeyDown={(e) => {
                                            const { key } = e;
                                            const currentLength = e.currentTarget.value.length;

                                            // Allow numeric input, decimal point, Backspace, and Tab
                                            if (!/[0-9]/.test(key) && key !== "Backspace" && key !== "." && key !== "Tab") {
                                                e.preventDefault();
                                            }

                                            // Allow only one decimal point
                                            if (key === "." && e.currentTarget.value.includes(".")) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {errors?.previouses?.[index]?.last_drawn_salary && (
                                        <div className="invalid-feedback">
                                            {errors.previouses[index].last_drawn_salary.message} {/* Display the error message */}
                                        </div>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Form.Group className="mb-4" controlId={`manager-${index}`}>
                                    <Form.Label>Reporting Manager Name</Form.Label>
                                    <Form.Control
                                        disabled={disabled}
                                        type="text"
                                        name={`previouses.${index}.reporting_manager_name`}
                                        placeholder="Manager Name"
                                        className={classNames("", {
                                            "is-invalid": errors?.previouses?.[index]?.reporting_manager_name, // Adjusted error checking
                                        })}
                                        {...register(`previouses.${index}.reporting_manager_name`, {
                                            required: "Enter Amount",
                                            pattern: {
                                                value: /^[A-Za-z\s]*$/,
                                                message: "Only letters and spaces are allowed",
                                            },
                                        })}
                                        onKeyDown={(e) => {
                                            // Allow only letters and spaces
                                            if (!/^[A-Za-z\s]$/.test(e.key) && e.key !== "Backspace") {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {errors?.previouses?.[index]?.reporting_manager_name && (
                                        <div className="invalid-feedback">
                                            {errors.previouses[index].reporting_manager_name.message} {/* Display the error message */}
                                        </div>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Form.Group className="mb-4" controlId={`mobileNo-${index}`}>
                                    <Form.Label>Conatct Person Mobile No</Form.Label>
                                    <Form.Control
                                        disabled={disabled}
                                        type="text"
                                        name={`previouses.${index}.contact_no`}
                                        placeholder="Contact No"
                                        className={classNames("", {
                                            "is-invalid": errors?.previouses?.[index]?.contact_no, // Adjusted error checking
                                        })}
                                        {...register(`previouses.${index}.contact_no`, {
                                            required: "Enter Amount",
                                            validate: (value) => value.length === 10 || "Manager Contact number must be exactly 10 digits",
                                        })}
                                        onKeyDown={(event) => {
                                            const { key } = event;
                                            const value = event.target.value;

                                            // Prevent non-digit characters except Backspace and Tab
                                            if (!/[0-9]/.test(key) && key !== "Backspace" && key !== "Tab") {
                                                event.preventDefault();
                                            }

                                            // Prevent input if length exceeds 10 digits
                                            if (value?.length >= 10 && key !== "Backspace" && key !== "Tab") {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                    {errors?.previouses?.[index]?.contact_no && (
                                        <div className="invalid-feedback">
                                            {errors.previouses[index].contact_no.message} {/* Display the error message */}
                                        </div>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Form.Label>Duration Of stay</Form.Label>
                                <Row>
                                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                        <Form.Group className="mb-4" controlId={`fromDate-${index}`}>
                                            <Form.Label>From</Form.Label>
                                            <Form.Control
                                                disabled={disabled}
                                                type="date"
                                                max={new Date().toISOString().split("T")[0]}
                                                name={`previouses.${index}.from_date`}
                                                placeholder="Date"
                                                className={classNames("", {
                                                    "is-invalid": errors?.previouses?.[index]?.from_date, // Adjusted error checking
                                                })}
                                                {...register(`previouses.${index}.from_date`, {
                                                    required: "Date Required",
                                                })}
                                            />
                                            {errors?.previouses?.[index]?.from_date && (
                                                <div className="invalid-feedback">
                                                    {errors.previouses[index].from_date.message} {/* Display the error message */}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Col>

                                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                        <Form.Group className="mb-4" controlId={`toDate-${index}`}>
                                            <Form.Label>To</Form.Label>
                                            <Form.Control
                                                disabled={disabled}
                                                type="date"
                                                name={`previouses.${index}.to_date`}
                                                placeholder="Date"
                                                max={new Date().toISOString().split("T")[0]}
                                                className={classNames("", {
                                                    "is-invalid": errors?.previouses?.[index]?.to_date, // Adjusted error checking
                                                })}
                                                {...register(`previouses.${index}.to_date`, {
                                                    required: "Date Required",
                                                })}
                                            />
                                            {errors?.previouses?.[index]?.to_date && (
                                                <div className="invalid-feedback">
                                                    {errors.previouses[index].to_date.message} {/* Display the error message */}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            {/* <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Form.Group className="mb-4" controlId={`employeeID-${index}`}>
                                    <Form.Label>Employee ID</Form.Label>
                                    <Form.Control type="number" placeholder="Enter Employee ID" />
                                </Form.Group>
                            </Col> */}

                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <div>
                                    <Form.Label>Document Type</Form.Label>
                                    <Controller
                                        name={`previouses.${index}.document_type`} // name of the field
                                        control={control}
                                        rules={{
                                            required: "Select Document",
                                        }}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <Select
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderColor: errors?.previouses?.[index]?.document_type ? "red" : baseStyles.borderColor,
                                                    }),
                                                }}
                                                // {...field} 
                                                options={documents}
                                                onChange={(selectedValue) => {
                                                    onChange(selectedValue);
                                                }}
                                                onBlur={onBlur}
                                                value={value}
                                                ref={ref}
                                                isDisabled={disabled}
                                            />
                                        )}
                                    />
                                    {/* {documents.map((item) =>
                                        <Form.Check
                                            disabled={disabled}
                                            key={index}
                                            type='radio'
                                            label={item?.label}
                                            value={item?.value} // Set the value for each radio button
                                            {...register(`previouses.${index}.document_type`, {
                                                required: "Select Document Type",
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.previouses?.[index]?.document_type,
                                            })}
                                        />
                                    )} */}
                                    {errors?.previouses?.[index]?.document_type && (
                                        <div className="invalid-feedback">
                                            {errors.previouses[index].document_type.message} {/* Display the error message */}
                                        </div>
                                    )}

                                </div>
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Form.Label>Employee Type</Form.Label>
                                <Controller

                                    name={`previouses.${index}.employee_type_id`} // name of the field
                                    control={control}
                                    rules={{
                                        required: "Select Employee Type",
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Select
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: errors?.previouses?.[index]?.employee_type_id ? "red" : baseStyles.borderColor,
                                                }),
                                            }}
                                            // {...field} 
                                            options={employeeTypes}
                                            onChange={(selectedValue) => {
                                                onChange(selectedValue);
                                            }}
                                            onBlur={onBlur}
                                            value={value}
                                            ref={ref}
                                            isDisabled={disabled}
                                        />
                                    )}
                                />
                                {errors?.previouses?.[index]?.employee_type_id && (
                                    <span className="text-danger">
                                        {errors?.previouses?.[index]?.employee_type_id.message}
                                    </span>
                                )}
                            </Col>
                            {index !== 0 && !disabled &&
                                <div className='text-end'>
                                    <button className='add-button' type='button' onClick={() => removePrevious(index)} ><FontAwesomeIcon icon={faMinus} /></button>
                                </div>
                            }
                            <hr className="mt-4" />
                        </Row>
                    )}
                </Form>
                {!disabled &&
                    <div className='text-end'>
                        <button className='add-button' type='button' onClick={() => appendPrevious({})}>
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>
                }
            </div>
        </div>
    );
};

export default PreviousEmployer;
