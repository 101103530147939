import { Modal } from "react-bootstrap";
import "../../../CommonCss/ModalCommonCss.css";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { Context } from "../../../../../utils/context";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import ModalSave from "../../../../common/ModalSave";
import AddedLottieModal from "../../../commonLottie/AddedLottieModal";

const AddModal = (props) => {
  const [showLottie, setshowLottie] = useState(false);
  const { postData } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      console.log(data);

      const sendData = new FormData();
      sendData.append("name", data?.name);

      const response = await postData(`/hrms-masters/employee_type`, sendData);
      console.log(response);
      if (response?.success) {
        setshowLottie();
        reset();
      } else {
        setshowLottie();
      }
      setTimeout(() => {
        setshowLottie({ code: 0 });
        props.onHide();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="modal-common-class-hrms">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-modal"
      >
        <Modal.Body>
          <div className="text-center">
            <h4 className="modal-title-common">Add Employee Type</h4>
          </div>

          <Container>
            <Row>
              <Col md={12}>
                <Container>
                  <Row className="">
                    <Col xxl={12} xl={12} md={10}>
                      <Form onSubmit={handleSubmit(onSubmit)} role="form">
                        <Row>
                          <Col lg={8} md={10} className=" mx-auto Add-content">
                            <Row>
                              <div className="main-form-section mt-5">
                                <Form.Label>Employee Type</Form.Label>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      name="name"
                                      placeholder="Employee Type"
                                      className={classNames("", {
                                        "is-invalid": errors?.name,
                                      })}
                                      {...register("name", {
                                        required: "Employee Type is required",
                                      })}
                                    />
                                  </InputGroup>
                                  {errors.name && (
                                    <span className="text-danger">
                                      {errors.name.message}
                                    </span>
                                  )}
                                </Form.Group>
                              </div>
                            </Row>

                            <Row className="mt-5 pb-3">
                              <div className="d-flex justify-content-center">
                                <Link>
                                  <button
                                    className="cancel-button me-2"
                                    onClick={props.onHide}
                                  >
                                    Cancel
                                  </button>
                                </Link>

                                <button
                                  className="save-button"
                                  name={"save"}
                                  type="submit"
                                >
                                  Save
                                </button>
                              </div>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>

      <AddedLottieModal
        pageTitle="Employee Type"
        show={showLottie}
        onHide={() => setshowLottie(false)}
      />
    </section>
  );
};

export default AddModal;
